import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import React from 'react';
import {toast} from 'react-toastify';

import {withContext} from '../decorators';
import {Layout as Small} from '../layouts/small';
import Logo from '../logo.png';

class Route extends React.Component {
  componentDidMount = () => {
    const email = new URL(location.href).searchParams.get('email');
    const property_codes = new URL(location.href).searchParams.get('property_codes');
    const type = new URL(location.href).searchParams.get('type');
    const token = new URL(location.href).searchParams.get('token');
    if (email !== null && property_codes != null && type !== null && token !== null) {
      toast.success('You have signed in successfully');
      this.props.context.signIn(this.props.history.push, email, property_codes, type, token);
    }
  };

  render = () => {
    return (
      <section className="align-items-center bg-primary container-fluid d-flex min-vw-100 min-vh-100">
        <div className="justify-content-center row w-100">
          <div className="col-lg-3 col-md-6 col-sm-12 py-3">
            <h1 className="mb-4 text-center text-white">
              <img alt="Logo" className="w-100" src={Logo} />
            </h1>
            <div className="bg-white rounded-lg p-3 shadow-lg">
              {this.renderError()}
              <button className="btn btn-block btn-primary" onClick={this.handleClick}>
                Sign In
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  };

  renderError = () => {
    const error = new URL(location.href).searchParams.get('error');
    if (error !== null) {
      return (
        <div className="row">
          <div className="col-12">
            <div className="alert alert-danger">
              <p className="mb-0">
                <FontAwesomeIcon className="mr-2" fixedWidth icon={faExclamationCircle} />
                {error}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  handleClick = () => {
    window.location.href = '/api/sign-in';
  };
}

Route = withContext(Route, 'Public', Small);

export {Route};
