import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import $ from 'jquery';
import React from 'react';
import {toast} from 'react-toastify';

import {communitiesIDClonePost} from '../requests';

class Modal extends React.Component {
  state = {
    loading: false,
    exception: null,
  };

  handleYes = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    communitiesIDClonePost(this.props.context.token, this.props.community.id).then(
      (response) => {
        this.setState({
          loading: false,
          exception: null,
        });
        toast.success(`${this.props.community.name} was cloned into ${response.data.community.name} successfully`);
        this.handleNo();
      },
      (response) => {
        this.setState({
          loading: false,
          exception: response.data.error,
        });
        toast.error(`${this.props.community.name} was not cloned successfully`);
      },
    );
  };

  handleNo = () => {
    const {community} = this.props;
    if (community !== null) {
      $(this.modal).modal('hide');
      this.props.handleClose();
    }
  };

  render = () => {
    const {community} = this.props;
    if (community !== null) {
      $(this.modal).modal('show');
    }
    return (
      <div className="fade modal" data-backdrop="true" id="clone" ref={(modal) => (this.modal = modal)}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Clone</h5>
              <button aria-label="Close" className="close" onClick={this.handleNo} type="button">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="mb-0">
                {this.renderException()}
                Are you sure you want to clone <strong>{community ? community.name : null}</strong>?
              </p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-danger" onClick={this.handleNo} type="button">
                No
              </button>
              <button className="btn btn-success" onClick={this.handleYes} type="button">
                {this.state.loading ? <FontAwesomeIcon fixedWidth icon={faSpinner} spin /> : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return <div className="alert alert-danger">{this.state.exception}</div>;
  };
}

export {Modal};
