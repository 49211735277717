import React from 'react';

const Context = React.createContext();

const Consumer = Context.Consumer;

class Provider extends React.Component {
  state = {
    email: window.localStorage.getItem('email'),
    property_codes: window.localStorage.getItem('property_codes'),
    type: window.localStorage.getItem('type'),
    token: window.localStorage.getItem('token'),
  };

  signIn = (push, email, property_codes, type, token) => {
    this.setState(
      {
        email: email,
        property_codes: property_codes,
        type: type,
        token: token,
      },
      () => {
        window.localStorage.setItem('email', email);
        window.localStorage.setItem('property_codes', property_codes);
        window.localStorage.setItem('type', type);
        window.localStorage.setItem('token', token);
        this.refresh();
        push('/');
      },
    );
  };

  signOut = (push) => {
    this.setState(
      {
        email: null,
        property_codes: null,
        type: null,
        token: null,
      },
      () => {
        window.localStorage.clear();
        push('/sign-out');
      },
    );
  };

  refresh = () => {
    window.localStorage.setItem('timestamp', Math.round(Date.now() / 1000));
  };

  isValidA = () => {
    if (this.state.email === null) {
      return false;
    }
    if (this.state.property_codes === null) {
      return false;
    }
    if (this.state.type === null) {
      return false;
    }
    if (this.state.token === null) {
      return false;
    }
    return true;
  };

  isValidB = () => {
    const timestampsNew = Math.round(Date.now() / 1000);
    const timestampsOld = parseInt(window.localStorage.getItem('timestamp'), 10);
    const seconds = 60 * 60 * 2;
    return timestampsNew - timestampsOld < seconds;
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          signIn: this.signIn,
          signOut: this.signOut,
          refresh: this.refresh,
          isValidA: this.isValidA,
          isValidB: this.isValidB,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export {Context, Consumer, Provider};
