import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import _ from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';

import {buildTitle} from '../builders';
import {withContext} from '../decorators';
import {Form} from '../forms/competitors';
import {Layout as Large} from '../layouts/large';
import {communitiesIDCompetitorsGet, communitiesIDCompetitorsPost} from '../requests';
import {Slot as Tabs} from '../slots/tabs';

class Route extends React.Component {
  state = {
    loading: false,
    exception: null,
    community: null,
    competitorsCardinalCommunities: [],
    competitorsNonCardinalCommunities: [],
    optionsCardinalCommunities: [],
    optionsNonCardinalCommunities: [],
  };

  componentDidMount = () => {
    this.request();
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    communitiesIDCompetitorsGet(this.props.context.token, this.props.match.params.id).then(
      (response) => {
        const {
          community,
          competitorsCardinalCommunities,
          competitorsNonCardinalCommunities,
          optionsCardinalCommunities,
          optionsNonCardinalCommunities,
        } = this.buildState(response.data);
        this.setState({
          loading: false,
          exception: null,
          community: community,
          competitorsCardinalCommunities: competitorsCardinalCommunities,
          competitorsNonCardinalCommunities: competitorsNonCardinalCommunities,
          optionsCardinalCommunities: optionsCardinalCommunities,
          optionsNonCardinalCommunities: optionsNonCardinalCommunities,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          community: null,
          competitorsCardinalCommunities: [],
          competitorsNonCardinalCommunities: [],
          optionsCardinalCommunities: [],
          optionsNonCardinalCommunities: [],
        });
      },
    );
  };

  handleSubmit = (values, {setSubmitting}) => {
    communitiesIDCompetitorsPost(
      this.props.context.token,
      this.props.match.params.id,
      _.concat(
        [],
        _.map(_.cloneDeep(values.cardinal_communities), this.decode),
        _.map(_.cloneDeep(values.non_cardinal_communities), this.decode),
      ),
    ).then(
      (response) => {
        setSubmitting(false);
        toast.success(`${response.data.community.name} was saved successfully`);
        if (values.redirect) {
          this.props.history.push(`${this.props.url(response.data.community)}${this.props.next}`);
        } else {
          const {
            community,
            competitorsCardinalCommunities,
            competitorsNonCardinalCommunities,
            optionsCardinalCommunities,
            optionsNonCardinalCommunities,
          } = this.buildState(response.data);
          this.setState({
            community: community,
            competitorsCardinalCommunities: competitorsCardinalCommunities,
            competitorsNonCardinalCommunities: competitorsNonCardinalCommunities,
            optionsCardinalCommunities: optionsCardinalCommunities,
            optionsNonCardinalCommunities: optionsNonCardinalCommunities,
          });
        }
      },
      (error) => {
        setSubmitting(false);
        toast.error(error.response.data.error);
      },
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">
          {buildTitle(this.props.classification, this.state.community ? [this.state.community.name, 'Edit'] : [])}
        </h1>
        {this.renderTabs()}
        {this.renderLoading()}
        {this.renderException()}
        {this.renderForm()}
      </React.Fragment>
    );
  };

  renderTabs = () => {
    return (
      <Tabs
        classification={this.props.classification}
        match={this.props.match}
        one={this.props.one}
        two={this.props.two}
        url={this.props.url}
      />
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return (
      <div className="alert alert-danger">
        <p className="mb-0">{this.state.exception}</p>
      </div>
    );
  };

  renderForm = () => {
    if (this.state.loading) {
      return null;
    }
    if (this.state.exception) {
      return null;
    }
    if (this.state.community === null) {
      return null;
    }
    return (
      <Form
        classification={this.props.classification}
        community={this.state.community}
        competitorsCardinalCommunities={this.state.competitorsCardinalCommunities}
        competitorsNonCardinalCommunities={this.state.competitorsNonCardinalCommunities}
        context={this.props.context}
        handleSubmit={this.handleSubmit}
        history={this.props.history}
        optionsCardinalCommunities={this.state.optionsCardinalCommunities}
        optionsNonCardinalCommunities={this.state.optionsNonCardinalCommunities}
      />
    );
  };

  buildState = (data) => {
    return {
      community: data.community,
      competitorsCardinalCommunities: _.filter(
        data.competitors,
        (competitor) => competitor.target.classification === 'Cardinal Community',
      ),
      competitorsNonCardinalCommunities: _.filter(
        data.competitors,
        (competitor) => competitor.target.classification === 'Non-Cardinal Community',
      ),
      optionsCardinalCommunities: _.map(
        _.filter(data.options, (competitor) => competitor.classification === 'Cardinal Community'),
        this.encode,
      ),
      optionsNonCardinalCommunities: _.map(
        _.filter(data.options, (competitor) => competitor.classification === 'Non-Cardinal Community'),
        this.encode,
      ),
    };
  };
}

Route = withContext(Route, 'Private', Large);
Route = withRouter(Route);

export {Route};
