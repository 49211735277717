import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import {Field, FieldArray, Form as F, Formik} from 'formik';
import _ from 'lodash';
import React from 'react';
import * as yup from 'yup';

import {buildCancel, buildClassName, buildSave, buildSaveAndNext} from '../builders';
import {Slot as Prompt} from '../slots/prompt';

class Form extends React.Component {
  render = () => {
    return (
      <Formik
        initialValues={this.buildInitialValues()}
        onSubmit={this.props.handleSubmit}
        render={this.buildRender}
        validationSchema={this.buildValidationSchema()}
      />
    );
  };

  renderPreviousYear = () => {
    return (
      <a className="btn btn-primary mb-3 mr-3 text-white" onClick={() => this.props.handlePrevious()}>
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faArrowCircleLeft} />
        Previous Year
      </a>
    );
  };

  renderNextYear = () => {
    return (
      <a className="btn btn-primary mb-3 mr-3 text-white" onClick={() => this.props.handleNext()}>
        Next Year
        <FontAwesomeIcon className="ml-2" fixedWidth icon={faArrowCircleRight} />
      </a>
    );
  };

  buildDisabled = (types) => {
    if (types.indexOf(this.props.context.type) === -1) {
      return true;
    }
    return false;
  };

  buildField = (
    name,
    arrayHelpers,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitForm,
    touched,
    values,
    value,
    key,
  ) => {
    return (
      <React.Fragment key={key}>
        <tr key={key}>
          <td className="text-center">{value.year}</td>
          <td className="text-center">{value.week}</td>
          <td className="text-center">{value.date}</td>
          {this.props.community.type === 'Student' ? (
            <td className="p-1 text-center">
              <Field
                className={`form-control form-control-sm ${buildClassName(
                  errors[name] && errors[name][key] && errors[name][key]['prelease_goal'],
                  touched[name] && touched[name][key] && touched[name][key]['prelease_goal'],
                )}`}
                component="input"
                disabled={this.buildDisabled(['HQ'])}
                max="1"
                min="0"
                name={`${name}.${key}.prelease_goal`}
                step="0.0001"
                type="number"
              />
            </td>
          ) : null}
          {this.props.community.type === 'Conventional' ? (
            <td className="p-1 text-center">
              <Field
                className={`form-control form-control-sm ${buildClassName(
                  errors[name] && errors[name][key] && errors[name][key]['occupancy_goal'],
                  touched[name] && touched[name][key] && touched[name][key]['occupancy_goal'],
                )}`}
                component="input"
                disabled={this.buildDisabled(['HQ'])}
                max="1"
                min="0"
                name={`${name}.${key}.occupancy_goal`}
                step="0.0001"
                type="number"
              />
            </td>
          ) : null}
        </tr>
      </React.Fragment>
    );
  };

  buildFieldArray = (
    name,
    arrayHelpers,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitForm,
    touched,
    values,
  ) => {
    return _.map(values[name], (value, key) =>
      this.buildField(
        name,
        arrayHelpers,
        errors,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        submitForm,
        touched,
        values,
        value,
        key,
      ),
    );
  };

  buildInitialValues = () => {
    return {
      items: _.map(this.props.items, (item) => {
        const pao = _.find(this.props.preleases_and_occupancies, (pao) => {
          return pao.year === item.year && pao.week === item.week;
        });
        return {
          year: item.year,
          week: item.week,
          date: item.date,
          prelease_goal: pao ? pao.prelease_goal || 0.0 : 0.0,
          occupancy_goal: pao ? pao.occupancy_goal || 0.0 : 0.0,
        };
      }),
    };
  };

  buildRender = ({
    dirty,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitCount,
    submitForm,
    touched,
    values,
  }) => {
    return (
      <F>
        <Prompt dirty={dirty} submitCount={submitCount} />
        <table className="table table-bordered table-hover table-sm table-responsive-sm">
          <colgroup>
            <col width="10%"></col>
            <col width="10%"></col>
            <col width="30%"></col>
            {this.props.community.type === 'Student' ? <col width="50%"></col> : null}
            {this.props.community.type === 'Conventional' ? <col width="50%"></col> : null}
          </colgroup>
          <tbody>
            <tr>
              <th className="text-center">Year</th>
              <th className="text-center">Week</th>
              <th className="text-center">Week Ending Date</th>
              {this.props.community.type === 'Student' ? <th className="text-center">Prelease Goal</th> : null}
              {this.props.community.type === 'Conventional' ? <th className="text-center">Occupancy Goal</th> : null}
            </tr>
            <FieldArray
              name="items"
              render={(arrayHelpers) =>
                this.buildFieldArray(
                  'items',
                  arrayHelpers,
                  errors,
                  isSubmitting,
                  setFieldTouched,
                  setFieldValue,
                  submitForm,
                  touched,
                  values,
                )
              }
            />
          </tbody>
        </table>
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <p className="align-items-center d-flex flex-column flex-fill flex-lg-row justify-content-start mb-0">
            {this.renderPreviousYear()}
            {this.renderNextYear()}
          </p>
          <div className="d-flex flex-fill justify-content-end mb-3">
            {buildCancel(() => this.props.history.goBack())}
            {buildSave(errors, isSubmitting, setFieldValue, submitForm)}
            {buildSaveAndNext(errors, isSubmitting, setFieldValue, submitForm)}
          </div>
        </div>
      </F>
    );
  };

  buildValidationSchema = () => {
    return yup.object().shape({
      items: yup.array().of(
        yup.object().shape({
          prelease_goal: yup
            .number()
            .max(1)
            .min(0),
          occupancy_goal: yup
            .number()
            .max(1)
            .min(0),
        }),
      ),
    });
  };
}

export {Form};
