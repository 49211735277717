import $ from 'jquery';
import React from 'react';

class Modal extends React.Component {
  componentDidMount = () => {
    $(this.modal).modal('show');
  };

  handleOK = () => {
    $(this.modal).modal('hide');
    this.props.handleOK();
  };

  render = () => {
    return (
      <div className="fade modal" data-backdrop="true" id="confirm" ref={(modal) => (this.modal = modal)}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Alert</h5>
              <button aria-label="Close" className="close" onClick={this.handleOK} type="button">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className="mb-0">{this.props.message}</p>
            </div>
            <div className="modal-footer">
              <button className="btn btn-danger" onClick={this.handleOK} type="button">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export {Modal};
