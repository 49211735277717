import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';

import {buildTitle} from '../builders';
import {withContext} from '../decorators';
import {Form} from '../forms/communities-fees';
import {Layout as Large} from '../layouts/large';
import {communitiesIDFeesGet, communitiesIDFeesPut} from '../requests';
import {Slot as Tabs} from '../slots/tabs';

class Route extends React.Component {
  state = {
    loading: false,
    exception: null,
    community: null,
  };

  componentDidMount = () => {
    this.request();
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    communitiesIDFeesGet(this.props.context.token, this.props.match.params.id).then(
      (response) => {
        this.setState({
          loading: false,
          exception: null,
          community: response.data.community,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          community: null,
        });
      },
    );
  };

  handleSubmit = (values, {setSubmitting}) => {
    communitiesIDFeesPut(this.props.context.token, this.props.match.params.id, values).then(
      (response) => {
        setSubmitting(false);
        toast.success(`${response.data.community.name} was saved successfully`);
        if (values.redirect) {
          this.props.history.push(`${this.props.url(response.data.community)}${this.props.next}`);
        } else {
          this.setState({
            community: response.data.community,
          });
        }
      },
      (error) => {
        setSubmitting(false);
        toast.error(error.response.data.error);
      },
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">
          {buildTitle(this.props.classification, this.state.community ? [this.state.community.name, 'Edit'] : [])}
        </h1>
        {this.renderTabs()}
        {this.renderLoading()}
        {this.renderException()}
        {this.renderForm()}
      </React.Fragment>
    );
  };

  renderTabs = () => {
    return (
      <Tabs
        classification={this.props.classification}
        match={this.props.match}
        one={this.props.one}
        two={this.props.two}
        url={this.props.url}
      />
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return (
      <div className="alert alert-danger">
        <p className="mb-0">{this.state.exception}</p>
      </div>
    );
  };

  renderForm = () => {
    if (this.state.loading) {
      return null;
    }
    if (this.state.exception) {
      return null;
    }
    if (this.state.community === null) {
      return null;
    }
    return (
      <Form
        classification={this.props.classification}
        community={this.state.community}
        context={this.props.context}
        handleSubmit={this.handleSubmit}
        history={this.props.history}
      />
    );
  };
}

Route = withContext(Route, 'Private', Large);
Route = withRouter(Route);

export {Route};
