import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import {format} from 'date-fns';
import {Form as F, Formik} from 'formik';
import React from 'react';
import * as yup from 'yup';

import {buildCancel, buildSave, buildSaveAndNext, buildTimestamp} from '../builders';
import {COMPETITOR_REVIEW, LEASING_NUMBERS, MARKETING_AND_RESIDENT_EVENTS, WAIVING_FEES} from '../constants';
import {optionsEncode, isMonday} from '../helpers';
import {Slot as Group} from '../slots/group';
import {Slot as Prompt} from '../slots/prompt';

class Form extends React.Component {
  render = () => {
    return (
      <Formik
        initialValues={this.buildInitialValues()}
        onSubmit={this.props.handleSubmit}
        render={this.buildRender}
        validationSchema={this.buildValidationSchema()}
      />
    );
  };

  renderPrelease = (errors, touched) => {
    if (
      this.props.classification === 'Cardinal Community' &&
      this.props.community.pricing_tool !== 'Yardi' &&
      this.props.community.pricing_tool !== 'Client System (Yardi)' &&
      this.props.community.type === 'Conventional'
    ) {
      return null;
    }
    if (this.props.classification === 'Cardinal Community' && this.props.community.type === 'Student') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input"
          context={this.props.context}
          errors={errors['prelease']}
          label="Prelease %"
          max="1"
          min="0"
          name="prelease"
          step="0.0001"
          touched={touched['prelease']}
          type="number"
        />
      </div>
    );
  };

  renderOccupancy = (errors, touched) => {
    if (this.props.classification === 'Cardinal Community' && this.props.community.pricing_tool === 'Entrata') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input"
          context={this.props.context}
          errors={errors['occupancy']}
          label="Occupancy %"
          max="1"
          min="0"
          name="occupancy"
          step="0.0001"
          touched={touched['occupancy']}
          type="number"
        />
      </div>
    );
  };

  renderTours = (errors, setFieldValue, touched) => {
    if (this.props.classification === 'Cardinal Community' && this.props.community.pricing_tool === 'Entrata') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input-dollar"
          context={this.props.context}
          errors={errors['tours']}
          label="Tours"
          min="0"
          name="tours"
          onChange={(event) => {
            const value = parseFloat(event.target.value);
            setFieldValue('tours', value ? value : 0.0);
          }}
          step="0.01"
          touched={touched['tours']}
          type="number"
        />
      </div>
    );
  };

  renderWeeklyNewLeases = (errors, setFieldValue, touched) => {
    if (this.props.classification === 'Cardinal Community' && this.props.community.pricing_tool === 'Entrata') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input-dollar"
          context={this.props.context}
          errors={errors['weekly_new_leases']}
          label="Weekly New Leases"
          min="0"
          name="weekly_new_leases"
          onChange={(event) => {
            const value = parseFloat(event.target.value);
            setFieldValue('weekly_new_leases', value ? value : 0.0);
          }}
          step="0.01"
          touched={touched['weekly_new_leases']}
          type="number"
        />
      </div>
    );
  };

  renderWeeklyRenewals = (errors, setFieldValue, touched) => {
    if (this.props.classification === 'Cardinal Community' && this.props.community.pricing_tool === 'Entrata') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input-dollar"
          context={this.props.context}
          errors={errors['weekly_renewals']}
          label="Weekly Renewals"
          min="0"
          name="weekly_renewals"
          onChange={(event) => {
            const value = parseFloat(event.target.value);
            setFieldValue('weekly_renewals', value ? value : 0.0);
          }}
          step="0.01"
          touched={touched['weekly_renewals']}
          type="number"
        />
      </div>
    );
  };

  renderTotalNewLeases = (errors, setFieldValue, touched) => {
    if (this.props.classification === 'Cardinal Community' && this.props.community.pricing_tool === 'Entrata') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input-dollar"
          context={this.props.context}
          errors={errors['total_new_leases']}
          label="Total New Leases"
          min="0"
          name="total_new_leases"
          onChange={(event) => {
            const value = parseFloat(event.target.value);
            setFieldValue('total_new_leases', value ? value : 0.0);
          }}
          step="0.01"
          touched={touched['total_new_leases']}
          type="number"
        />
      </div>
    );
  };

  renderTotalRenewal = (errors, setFieldValue, touched) => {
    if (this.props.classification === 'Cardinal Community' && this.props.community.pricing_tool === 'Entrata') {
      return null;
    }
    return (
      <div className="col-sm-12 col-md">
        <Group
          component="input-dollar"
          context={this.props.context}
          errors={errors['total_renewal']}
          label="Total Renewal "
          min="0"
          name="total_renewal"
          onChange={(event) => {
            const value = parseFloat(event.target.value);
            setFieldValue('total_renewal', value ? value : 0.0);
          }}
          step="0.01"
          touched={touched['total_renewal']}
          type="number"
        />
      </div>
    );
  };

  renderWaivingFees = (errors, setFieldTouched, setFieldValue, touched, values) => {
    return (
      <div className="col-sm-12 col-md">
        <Group
          className="select-all-small"
          component="select-all"
          context={this.props.context}
          label="Waiving Fees"
          name="waiving_fees"
          noOptionsMessage="There are no more options."
          onBlur={setFieldTouched}
          onChange={setFieldValue}
          options={optionsEncode(WAIVING_FEES)}
          value={values['waiving_fees']}
        />
      </div>
    );
  };

  renderWRSCC = (errors, touched, values) => {
    if (this.props.classification === 'Non-Cardinal Community') {
      return null;
    }
    return (
      <div>
        <h2 className="mb-3">Weekly Reporting Suite Client Commentary</h2>
        <div className="row">{this.renderCurrentAR(errors, touched, values)}</div>
        <div className="row">
          {this.renderLeasingNumbers(errors, touched, values)}
          {this.renderMarketingAndResidentEvents(errors, touched, values)}
          {this.renderCompetitorReview(errors, touched, values)}
        </div>
      </div>
    );
  };

  renderCurrentAR = (errors, touched, values) => {
    return (
      <div className="col">
        <Group
          component="input"
          context={this.props.context}
          errors={errors['current_ar']}
          label="Current A/R"
          name="current_ar"
          touched={touched['current_ar']}
          value={values['current_ar']}
        />
      </div>
    );
  };

  renderLeasingNumbers = (errors, touched, values) => {
    return (
      <div className="col">
        <Group
          component="textarea"
          context={this.props.context}
          errors={errors['leasing_numbers']}
          label="Leasing Numbers"
          name="leasing_numbers"
          rows="8"
          touched={touched['leasing_numbers']}
          value={values['leasing_numbers']}
        />
      </div>
    );
  };

  renderMarketingAndResidentEvents = (errors, touched, values) => {
    return (
      <div className="col">
        <Group
          component="textarea"
          context={this.props.context}
          errors={errors['marketing_and_resident_events']}
          label="Marketing and Resident Events"
          name="marketing_and_resident_events"
          rows="8"
          touched={touched['marketing_and_resident_events']}
          value={values['marketing_and_resident_events']}
        />
      </div>
    );
  };

  renderCompetitorReview = (errors, touched, values) => {
    return (
      <div className="col">
        <Group
          component="textarea"
          context={this.props.context}
          errors={errors['competitor_review']}
          label="Competitor Review"
          name="competitor_review"
          rows="8"
          touched={touched['competitor_review']}
          value={values['competitor_review']}
        />
      </div>
    );
  };

  renderNextWeek = () => {
    if (this.props.isCurrent()) {
      return null;
    }
    if (this.props.isPrevious()) {
      if (isMonday()) {
        return null;
      }
    }
    return (
      <a className="btn btn-primary mb-3 mr-3 text-white" onClick={() => this.props.handleNext()}>
        Next Week
        <FontAwesomeIcon className="ml-2" fixedWidth icon={faArrowCircleRight} />
      </a>
    );
  };

  buildInitialValues = () => {
    if (!this.props.prelease_and_occupancy) {
      return {
        prelease: 0.0,
        occupancy: 0.0,
        tours: 0.0,
        weekly_new_leases: 0.0,
        weekly_renewals: 0.0,
        total_new_leases: 0.0,
        total_renewal: 0.0,
        waiving_fees: [],
        leasing_numbers: LEASING_NUMBERS,
        marketing_and_resident_events: MARKETING_AND_RESIDENT_EVENTS,
        competitor_review: COMPETITOR_REVIEW,
        current_ar: '',
      };
    }
    return {
      prelease: this.props.prelease_and_occupancy.prelease || 0.0,
      occupancy: this.props.prelease_and_occupancy.occupancy || 0.0,
      tours: this.props.prelease_and_occupancy.tours || 0.0,
      weekly_new_leases: this.props.prelease_and_occupancy.weekly_new_leases || 0.0,
      weekly_renewals: this.props.prelease_and_occupancy.weekly_renewals || 0.0,
      total_new_leases: this.props.prelease_and_occupancy.total_new_leases || 0.0,
      total_renewal: this.props.prelease_and_occupancy.total_renewal || 0.0,
      waiving_fees: this.props.prelease_and_occupancy.waiving_fees || [],
      leasing_numbers: this.props.prelease_and_occupancy.leasing_numbers || LEASING_NUMBERS,
      marketing_and_resident_events:
        this.props.prelease_and_occupancy.marketing_and_resident_events || MARKETING_AND_RESIDENT_EVENTS,
      competitor_review: this.props.prelease_and_occupancy.competitor_review || COMPETITOR_REVIEW,
      current_ar: this.props.prelease_and_occupancy.current_ar || '',
    };
  };

  buildRender = ({
    dirty,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitCount,
    submitForm,
    touched,
    values,
  }) => {
    return (
      <F>
        <Prompt dirty={dirty} submitCount={submitCount} />
        <div className="row">
          {this.renderPrelease(errors, touched)}
          {this.renderOccupancy(errors, touched)}
          {this.renderWaivingFees(errors, setFieldTouched, setFieldValue, touched, values)}
        </div>
        <div className="row">
          {this.renderTours(errors, setFieldValue, touched)}
          {this.renderWeeklyNewLeases(errors, setFieldValue, touched)}
          {this.renderWeeklyRenewals(errors, setFieldValue, touched)}
        </div>
        <div className="row">
          {this.renderTotalNewLeases(errors, setFieldValue, touched)}
          {this.renderTotalRenewal(errors, setFieldValue, touched)}
        </div>
        {this.renderWRSCC(errors, touched, values)}
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <p className="align-items-center d-flex flex-column flex-fill flex-lg-row justify-content-start mb-0">
            <a className="btn btn-primary mb-3 mr-3 text-white" onClick={() => this.props.handlePrevious()}>
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faArrowCircleLeft} />
              Previous Week
            </a>
            <nobr className="mb-3 mr-3">
              Year: <strong className="ml-2">{this.props.year}</strong>
            </nobr>
            <nobr className="mb-3 mr-3">
              Week: <strong className="ml-2">{this.props.week}</strong>
            </nobr>
            <nobr className="mb-3 mr-3">
              Week Ending Date:{' '}
              <strong className="btn badge-primary ml-2 rounded-pill">{format(this.props.date, 'yyyy-LL-dd')}</strong>
            </nobr>
            <nobr className="mb-3 mr-3">
              Last Update:
              <strong className="ml-2">{buildTimestamp(this.props.prelease_and_occupancy.updated_at)}</strong>
            </nobr>
            {this.renderNextWeek()}
          </p>
          <div className="d-flex flex-fill justify-content-end mb-3">
            {buildCancel(() => this.props.history.goBack())}
            {buildSave(errors, isSubmitting, setFieldValue, submitForm)}
            {buildSaveAndNext(errors, isSubmitting, setFieldValue, submitForm)}
          </div>
        </div>
      </F>
    );
  };

  buildValidationSchema = () => {
    return yup.object().shape({
      prelease: yup
        .number()
        .max(1)
        .min(0)
        .required(),
      occupancy: yup
        .number()
        .max(1)
        .min(0)
        .required(),
    });
  };
}

export {Form};
