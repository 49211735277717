import React from 'react';
import {Prompt} from 'react-router-dom';

class Slot extends React.Component {
  render = () => {
    return (
      <Prompt
        message="Are you sure you want to leave this page? You have unsaved changes."
        when={this.props.dirty && this.props.submitCount === 0}
      />
    );
  };
}

export {Slot};
