import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import {faMinusSquare} from '@fortawesome/free-solid-svg-icons/faMinusSquare';
import {faPlusSquare} from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import {format} from 'date-fns';
import {FieldArray, Form as F, Formik} from 'formik';
import _ from 'lodash';
import React from 'react';
import * as yup from 'yup';

import {buildCancel, buildSave, buildSaveAndNext, buildTimestamp} from '../builders';
import {Slot as Group} from '../slots/group';
import {isMonday} from '../helpers';
import {Slot as Prompt} from '../slots/prompt';

class Form extends React.Component {
  state = {
    item: {
      name: '',
      description: '',
      beds: 0,
      baths: 0.0,
      count: 0,
      security_deposit: 0.0,
      square_footage: 0.0,
      tangible_item_value: 0.0,
      tangible_item_notes: '',
      gift_card_cash_equivalent: 0.0,
      one_time_free_rent: 0.0,
      monthly_concession: 0.0,
      market_rent: 0.0,
      effective_rent: 0.0,
      concession_type: [],
      max_premium: 0.0,
    },
    minus: -1,
  };

  render = () => {
    return (
      <Formik
        initialValues={this.buildInitialValues()}
        onSubmit={this.props.handleSubmit}
        render={this.buildRender}
        validationSchema={this.buildValidationSchema()}
      />
    );
  };

  renderPlus = (arrayHelpers) => {
    // Revert on July 3
    // if (this.props.context.type !== 'HQ') {
    //   return null;
    // }
    // Revert on July 3
    return (
      <a className="btn btn-info ml-3 text-white" onClick={() => arrayHelpers.push({...this.state.item})}>
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faPlusSquare} />
        Add
      </a>
    );
  };

  renderMinus = (arrayHelpers, key) => {
    if (this.props.context.type !== 'HQ') {
      return null;
    }
    return (
      <FontAwesomeIcon
        className="cursor ml-2"
        fixedWidth
        icon={faMinusSquare}
        onClick={() => arrayHelpers.remove(key)}
      />
    );
  };

  renderNextWeek = () => {
    if (this.props.isCurrent()) {
      return null;
    }
    if (this.props.isPrevious()) {
      if (isMonday()) {
        return null;
      }
    }
    return (
      <a className="btn btn-primary mb-3 mr-3 text-white" onClick={() => this.props.handleNext()}>
        Next Week
        <FontAwesomeIcon className="ml-2" fixedWidth icon={faArrowCircleRight} />
      </a>
    );
  };

  buildCount = () => {
    if (this.props.community.type === 'Conventional') {
      return `# of Units`;
    }
    if (this.props.community.type === 'Student') {
      return `# of Beds`;
    }
    return `# of Units`;
  };

  buildDisabled = (types) => {
    if (types.indexOf(this.props.context.type) === -1) {
      return true;
    }
    return false;
  };

  buildInitialValues = () => {
    if (this.props.unit_mix_details.length === 0) {
      return {
        items: [{...this.state.item}],
      };
    }
    return {
      items: this.props.unit_mix_details,
    };
  };

  buildLabel = (label) => {
    if (this.props.community.type === 'Conventional') {
      return `${label} Per Unit`;
    }
    if (this.props.community.type === 'Student') {
      if (label === 'Market Rent') {
        label = 'Asking Rent';
      }
      return `${label} Per Bed`;
    }
    return label;
  };

  buildRender = ({
    dirty,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitCount,
    submitForm,
    touched,
    values,
  }) => {
    return (
      <F>
        <Prompt dirty={dirty} submitCount={submitCount} />
        <FieldArray
          name="items"
          render={(arrayHelpers) =>
            this.buildFieldArray(
              'items',
              arrayHelpers,
              errors,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              submitForm,
              touched,
              values,
            )
          }
        />
      </F>
    );
  };

  buildField = (
    name,
    arrayHelpers,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitForm,
    touched,
    values,
    value,
    key,
  ) => {
    return (
      <React.Fragment key={key}>
        <div className="card mb-3">
          <div className="card-header">
            Row #{key + 1}
            {this.renderMinus(arrayHelpers, key)}
          </div>
          <div className="card-body pb-0">
            <div className="row">
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['name']}
                  id={`${name}.${key}.name`}
                  label="Floor Plan Name"
                  name={`${name}.${key}.name`}
                  touched={touched[name] && touched[name][key] && touched[name][key]['name']}
                  type="text"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['description']}
                  id={`${name}.${key}.description`}
                  label="Floor Plan Description"
                  name={`${name}.${key}.description`}
                  touched={touched[name] && touched[name][key] && touched[name][key]['description']}
                  type="text"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['beds']}
                  id={`${name}.${key}.beds`}
                  label="Bedrooms"
                  max="19"
                  min="0"
                  name={`${name}.${key}.beds`}
                  touched={touched[name] && touched[name][key] && touched[name][key]['beds']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['baths']}
                  id={`${name}.${key}.baths`}
                  label="Bathrooms"
                  max="19"
                  min="0"
                  name={`${name}.${key}.baths`}
                  step="0.25"
                  touched={touched[name] && touched[name][key] && touched[name][key]['baths']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['count']}
                  id={`${name}.${key}.count`}
                  label={this.buildCount()}
                  min="0"
                  name={`${name}.${key}.count`}
                  touched={touched[name] && touched[name][key] && touched[name][key]['count']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['CM', 'HQ'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['security_deposit']}
                  id={`${name}.${key}.security_deposit`}
                  label={this.buildLabel('Security Deposit')}
                  min="0"
                  name={`${name}.${key}.security_deposit`}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['security_deposit']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['square_footage']}
                  id={`${name}.${key}.square_footage`}
                  label={this.buildLabel('Square Footage')}
                  min="0"
                  name={`${name}.${key}.square_footage`}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['square_footage']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input-dollar"
                  disabled={this.buildDisabled(['CM', 'HQ'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['market_rent']}
                  id={`${name}.${key}.market_rent`}
                  label={this.buildLabel('Market Rent')}
                  min="0"
                  name={`${name}.${key}.market_rent`}
                  onChange={(event) => {
                    const value = parseFloat(event.target.value);
                    setFieldValue(`${name}.${key}.market_rent`, value);
                    setFieldValue(
                      `${name}.${key}.effective_rent`,
                      Math.round(
                        ((value * 12 -
                          values[name][key]['one_time_free_rent'] -
                          values[name][key]['monthly_concession'] * 12 -
                          values[name][key]['gift_card_cash_equivalent']) /
                          12) *
                          100,
                      ) / 100,
                    );
                  }}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['market_rent']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['tangible_item_value']}
                  id={`${name}.${key}.tangible_item_value`}
                  label="Tangible Item Value"
                  min="0"
                  name={`${name}.${key}.tangible_item_value`}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['tangible_item_value']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['tangible_item_notes']}
                  id={`${name}.${key}.tangible_item_notes`}
                  label="Tangible Item Notes"
                  name={`${name}.${key}.tangible_item_notes`}
                  touched={touched[name] && touched[name][key] && touched[name][key]['tangible_item_notes']}
                  type="text"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input-dollar"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['gift_card_cash_equivalent']}
                  id={`${name}.${key}.gift_card_cash_equivalent`}
                  label="Gift Card/Cash equivalent"
                  min="0"
                  name={`${name}.${key}.gift_card_cash_equivalent`}
                  onChange={(event) => {
                    const value = parseFloat(event.target.value);
                    setFieldValue(`${name}.${key}.gift_card_cash_equivalent`, value);
                    setFieldValue(
                      `${name}.${key}.effective_rent`,
                      Math.round(
                        ((values[name][key]['market_rent'] * 12 -
                          values[name][key]['one_time_free_rent'] -
                          values[name][key]['monthly_concession'] * 12 -
                          value) /
                          12) *
                          100,
                      ) / 100,
                    );
                  }}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['gift_card_cash_equivalent']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input-dollar"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['one_time_free_rent']}
                  id={`${name}.${key}.one_time_free_rent`}
                  label="One-time free rent"
                  min="0"
                  name={`${name}.${key}.one_time_free_rent`}
                  onChange={(event) => {
                    const value = parseFloat(event.target.value);
                    setFieldValue(`${name}.${key}.one_time_free_rent`, value);
                    setFieldValue(
                      `${name}.${key}.effective_rent`,
                      Math.round(
                        ((values[name][key]['market_rent'] * 12 -
                          value -
                          values[name][key]['monthly_concession'] * 12 -
                          values[name][key]['gift_card_cash_equivalent']) /
                          12) *
                          100,
                      ) / 100,
                    );
                  }}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['one_time_free_rent']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input-dollar"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['monthly_concession']}
                  id={`${name}.${key}.monthly_concession`}
                  label="Monthly concession"
                  min="0"
                  name={`${name}.${key}.monthly_concession`}
                  onChange={(event) => {
                    const value = parseFloat(event.target.value);
                    setFieldValue(`${name}.${key}.monthly_concession`, value);
                    setFieldValue(
                      `${name}.${key}.effective_rent`,
                      Math.round(
                        ((values[name][key]['market_rent'] * 12 -
                          values[name][key]['one_time_free_rent'] -
                          value * 12 -
                          values[name][key]['gift_card_cash_equivalent']) /
                          12) *
                          100,
                      ) / 100,
                    );
                  }}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['monthly_concession']}
                  type="number"
                />
              </div>
              <div className="col-sm-12 col-md-2">
                <Group
                  component="input"
                  disabled={true}
                  id={`${name}.${key}.effective_rent`}
                  label="Effective Rent $"
                  name={`${name}.${key}.effective_rent`}
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  type="number"
                  value={
                    Math.round(
                      ((values[name][key]['market_rent'] * 12 -
                        values[name][key]['one_time_free_rent'] -
                        values[name][key]['monthly_concession'] * 12 -
                        values[name][key]['gift_card_cash_equivalent']) /
                        12) *
                        100,
                    ) / 100
                  }
                />
              </div>
              {/*
              <div className="col-sm-12 col-md-2">
                <Group
                  className="select-all-small"
                  component="select-all"
                  disabled={this.buildDisabled(['CM', 'HQ'])}
                  id={`${name}.${key}.concession_type`}
                  label="Concession Types Offering"
                  name={`${name}.${key}.concession_type`}
                  noOptionsMessage="There are no more options."
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  options={optionsEncode(CONCESSION_TYPES)}
                  value={values[name][key]['concession_type']}
                />
              </div>
              */}
              <div className="col-sm-12 col-md-4">
                <Group
                  component="input"
                  disabled={this.buildDisabled(['HQ', 'CM'])}
                  errors={errors[name] && errors[name][key] && errors[name][key]['max_premium']}
                  id={`${name}.${key}.max_premium`}
                  label="Max Premium"
                  min="0"
                  name={`${name}.${key}.max_premium`}
                  step="0.01"
                  touched={touched[name] && touched[name][key] && touched[name][key]['max_premium']}
                  type="number"
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  buildFieldArray = (
    name,
    arrayHelpers,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitForm,
    touched,
    values,
  ) => {
    return (
      <React.Fragment>
        {_.map(values[name], (value, key) =>
          this.buildField(
            name,
            arrayHelpers,
            errors,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
            submitForm,
            touched,
            values,
            value,
            key,
          ),
        )}
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <p className="align-items-center d-flex flex-column flex-fill flex-lg-row justify-content-start mb-0">
            <a className="btn btn-primary mb-3 mr-3 text-white" onClick={() => this.props.handlePrevious()}>
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faArrowCircleLeft} />
              Previous Week
            </a>
            <nobr className="mb-3 mr-3">
              Year: <strong className="ml-2">{this.props.year}</strong>
            </nobr>
            <nobr className="mb-3 mr-3">
              Week: <strong className="ml-2">{this.props.week}</strong>
            </nobr>
            <nobr className="mb-3 mr-3">
              Week Ending Date:{' '}
              <strong className="btn badge-primary ml-2 rounded-pill">{format(this.props.date, 'yyyy-LL-dd')}</strong>
            </nobr>
            <nobr className="mb-3 mr-3">
              Last Update:
              <strong className="ml-2">{buildTimestamp(this.buildTimestamp())}</strong>
            </nobr>
            {this.renderNextWeek()}
          </p>
          <div className="d-flex flex-fill justify-content-end mb-3">
            {this.renderPlus(arrayHelpers)}
            {buildCancel(() => this.props.history.goBack())}
            {buildSave(errors, isSubmitting, setFieldValue, submitForm)}
            {buildSaveAndNext(errors, isSubmitting, setFieldValue, submitForm)}
          </div>
        </div>
      </React.Fragment>
    );
  };

  buildTimestamp = () => {
    const timestamps = _.map(
      _.filter(this.props.unit_mix_details, (unit_mix_detail) => unit_mix_detail.updated_at !== null),
      (unit_mix_detail) => unit_mix_detail.updated_at,
    );
    if (!timestamps.length) {
      return null;
    }
    return _.max(timestamps);
  };

  buildValidationSchema = () => {
    return yup.object().shape({
      items: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required()
            .max(50, 'Enter up to 50 characters'),
          beds: yup
            .number()
            .integer()
            .max(19)
            .min(0)
            .required(),
          baths: yup.number().required(),
          count: yup
            .number()
            .integer()
            .min(0)
            .required(),
          security_deposit: yup.number().required(),
          square_footage: yup
            .number()
            .integer()
            .max(9999)
            .min(1)
            .required(),
          tangible_item_value: yup
            .number()
            .integer()
            .min(0)
            .required(),
          gift_card_cash_equivalent: yup
            .number()
            .integer()
            .min(0)
            .required(),
          one_time_free_rent: yup
            .number()
            .integer()
            .min(0)
            .required(),
          monthly_concession: yup
            .number()
            .integer()
            .min(0)
            .required(),
          market_rent: yup
            .number()
            .positive()
            .min(0)
            .required(),
          effective_rent: yup.number().required(),
          max_premium: yup.number().required(),
        }),
      ),
    });
  };
}

export {Form};
