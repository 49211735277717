import _ from 'lodash';
import {getDay} from 'date-fns';

const optionsDecode = (options) => {
  return _.map(options, (option) => option.value);
};

const optionsEncode = (options) => {
  return _.map(options, (option) => {
    return {
      key: option,
      label: option,
      value: option,
    };
  });
};

const isMonday = () => {
  return getDay(new Date()) === 1;
};

export {optionsDecode, optionsEncode, isMonday};
