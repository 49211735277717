import React from 'react';

import {Slot as Footer} from '../slots/footer';
import {Slot as Header} from '../slots/header';

const Layout = (props) => {
  return (
    <React.Fragment>
      <Header context={props.context} />
      <div className="container-fluid px-3">{props.children}</div>
      <Footer />
    </React.Fragment>
  );
};

export {Layout};
