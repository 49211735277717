import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {addDays, endOfISOWeek, getISOWeek, getISOWeekYear, subDays} from 'date-fns';
import _ from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';

import {buildTitle} from '../builders';
import {withContext} from '../decorators';
import {Form} from '../forms/prelease-and-occupancy';
import {optionsDecode, optionsEncode, isMonday} from '../helpers';
import {Layout as Large} from '../layouts/large';
import {Modal as Alert} from '../modals/alert';
import {Modal as Confirm} from '../modals/confirm';
import {communitiesIDPreleaseAndOccupancyWeekYearGet, communitiesIDPreleaseAndOccupancyWeekYearPost} from '../requests';
import {Slot as Tabs} from '../slots/tabs';

class Route extends React.Component {
  state = {
    loading: false,
    exception: null,
    year: getISOWeekYear(new Date()),
    week: getISOWeek(new Date()),
    date: endOfISOWeek(new Date()),
    community: null,
    prelease_and_occupancy: null,
    alert: true,
    next_week: false,
    previous_week: false,
  };

  componentDidMount = () => {
    if (isMonday()) {
      this.handlePreviousYes();
    } else {
      this.request();
    }
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    communitiesIDPreleaseAndOccupancyWeekYearGet(
      this.props.context.token,
      this.props.match.params.id,
      this.state.year,
      this.state.week,
    ).then(
      (response) => {
        const {community, prelease_and_occupancy} = this.buildState(response.data);
        this.setState({
          loading: false,
          exception: null,
          community: community,
          prelease_and_occupancy: prelease_and_occupancy,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          community: null,
          prelease_and_occupancy: null,
        });
      },
    );
  };

  decode = (item) => {
    item.waiving_fees = optionsDecode(item.waiving_fees);
    return item;
  };

  encode = (item) => {
    item.waiving_fees = optionsEncode(item.waiving_fees);
    return item;
  };

  handleOK = () => {
    this.setState({
      alert: false,
    });
  };

  handlePrevious = () => {
    this.setState({
      previous_week: true,
      next_week: false,
    });
  };

  handlePreviousYes = () => {
    this.setState(
      {
        previous_week: false,
        next_week: false,
      },
      () => {
        const date = subDays(this.state.date, 7);
        this.setState(
          {
            year: getISOWeekYear(date),
            week: getISOWeek(date),
            date: endOfISOWeek(date),
          },
          () => this.request(),
        );
      },
    );
  };

  handlePreviousNo = () => {
    this.setState({
      next_week: false,
      previous_week: false,
    });
  };

  handleNext = () => {
    this.setState({
      previous_week: false,
      next_week: true,
    });
  };

  handleNextYes = () => {
    this.setState(
      {
        previous_week: false,
        next_week: false,
      },
      () => {
        const date = addDays(this.state.date, 7);
        this.setState(
          {
            year: getISOWeekYear(date),
            week: getISOWeek(date),
            date: endOfISOWeek(date),
          },
          () => this.request(),
        );
      },
    );
  };

  handleNextNo = () => {
    this.setState({
      next_week: false,
      previous_week: false,
    });
  };

  handleSubmit = (values, {setSubmitting}) => {
    communitiesIDPreleaseAndOccupancyWeekYearPost(
      this.props.context.token,
      this.props.match.params.id,
      this.state.year,
      this.state.week,
      this.decode({..._.cloneDeep(values)}),
    ).then(
      (response) => {
        setSubmitting(false);
        toast.success(`${response.data.community.name} was saved successfully`);
        if (values.redirect) {
          this.props.history.push(`${this.props.url(response.data.community)}${this.props.next}`);
        } else {
          const {community, prelease_and_occupancy} = this.buildState(response.data);
          this.setState({
            community: community,
            prelease_and_occupancy: prelease_and_occupancy,
          });
        }
      },
      (error) => {
        setSubmitting(false);
        toast.error(error.response.data.error);
      },
    );
  };

  isCurrent = () => {
    if (this.state.week === getISOWeek(new Date())) {
      return true;
    }
    return false;
  };

  isPrevious = () => {
    if (this.state.week === getISOWeek(new Date()) - 1) {
      return true;
    }
    return false;
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">
          {buildTitle(this.props.classification, this.state.community ? [this.state.community.name, 'Edit'] : [])}
        </h1>
        {this.renderTabs()}
        {this.renderLoading()}
        {this.renderException()}
        {this.renderAlert()}
        {this.renderModal()}
        {this.renderForm()}
      </React.Fragment>
    );
  };

  renderTabs = () => {
    return (
      <Tabs
        classification={this.props.classification}
        match={this.props.match}
        one={this.props.one}
        two={this.props.two}
        url={this.props.url}
      />
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return (
      <div className="alert alert-danger">
        <p className="mb-0">{this.state.exception}</p>
      </div>
    );
  };

  renderAlert = () => {
    if (!isMonday()) {
      return null;
    }
    if (!this.state.alert) {
      return null;
    }
    return (
      <Alert
        handleOK={this.handleOK}
        message="You are now defaulted to the previous week. You will no longer need to update the week prior and you will not
      be able to update the current week until Tuesday. The most recent week will still reflect in Keystone."
      />
    );
  };

  renderModal = () => {
    if (this.state.previous_week) {
      return (
        <Confirm
          handleNo={this.handlePreviousNo}
          handleYes={this.handlePreviousYes}
          message="Are you sure you want to edit the numbers of the previous week?"
        />
      );
    }
    if (this.state.next_week) {
      return (
        <Confirm
          handleNo={this.handleNextNo}
          handleYes={this.handleNextYes}
          message="Are you sure you want to edit the numbers of the next week?"
        />
      );
    }
    return null;
  };

  renderForm = () => {
    if (this.state.loading) {
      return null;
    }
    if (this.state.exception) {
      return null;
    }
    if (this.state.community === null) {
      return null;
    }
    return (
      <Form
        classification={this.props.classification}
        community={this.state.community}
        context={this.props.context}
        date={this.state.date}
        handlePrevious={this.handlePrevious}
        handleNext={this.handleNext}
        handleSubmit={this.handleSubmit}
        history={this.props.history}
        isCurrent={this.isCurrent}
        isPrevious={this.isPrevious}
        prelease_and_occupancy={this.state.prelease_and_occupancy}
        week={this.state.week}
        year={this.state.year}
      />
    );
  };

  buildState = (data) => {
    return {
      community: data.community,
      prelease_and_occupancy: this.encode(data.prelease_and_occupancy),
    };
  };
}

Route = withContext(Route, 'Private', Large);
Route = withRouter(Route);

export {Route};
