import axios from 'axios';
import {getUnixTime} from 'date-fns';

const communitiesGet = (token, classification, status, keywords, orderByColumn, orderByDirection, limit, offset) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      classification: classification,
      status: status,
      keywords: keywords,
      order_by_column: orderByColumn,
      order_by_direction: orderByDirection,
      limit: limit,
      offset: offset,
      timestamp: getUnixTime(new Date()),
    },
    url: '/communities',
  });
};

const communitiesIDClonePost = (token, id) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'POST',
    url: `/communities/${id}/clone`,
  });
};

const communitiesIDDeletePost = (token, id) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'POST',
    url: `/communities/${id}/delete`,
  });
};

const communitiesPost = (token, community) => {
  return axios.request({
    data: {
      community: community,
    },
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'POST',
    url: '/communities',
  });
};

const communitiesIDCommunityGet = (token, id) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      timestamp: getUnixTime(new Date()),
    },
    url: `/communities/${id}/community`,
  });
};

const communitiesIDCommunityPut = (token, id, community) => {
  return axios.request({
    data: {
      community: community,
    },
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'PUT',
    url: `/communities/${id}/community`,
  });
};

const communitiesIDUtilitiesAndFeaturesGet = (token, id) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      timestamp: getUnixTime(new Date()),
    },
    url: `/communities/${id}/utilities-and-features`,
  });
};

const communitiesIDUtilitiesAndFeaturesPut = (token, id, community) => {
  return axios.request({
    data: {
      community: community,
    },
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'PUT',
    url: `/communities/${id}/utilities-and-features`,
  });
};

const communitiesIDFeesGet = (token, id) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      timestamp: getUnixTime(new Date()),
    },
    url: `/communities/${id}/fees`,
  });
};

const communitiesIDFeesPut = (token, id, community) => {
  return axios.request({
    data: {
      community: community,
    },
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'PUT',
    url: `/communities/${id}/fees`,
  });
};

const communitiesIDUnitMixDetailsWeekYearGet = (token, id, year, week) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      timestamp: getUnixTime(new Date()),
    },
    url: `/communities/${id}/unit-mix-details/${year}/${week}`,
  });
};

const communitiesIDUnitMixDetailsWeekYearPost = (token, id, year, week, unitMixDetails) => {
  return axios.request({
    data: {
      unit_mix_details: unitMixDetails,
    },
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'POST',
    url: `/communities/${id}/unit-mix-details/${year}/${week}`,
  });
};

const communitiesIDCompetitorsGet = (token, id) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      timestamp: getUnixTime(new Date()),
    },
    url: `/communities/${id}/competitors`,
  });
};

const communitiesIDCompetitorsPost = (token, id, competitors) => {
  return axios.request({
    data: {
      competitors: competitors,
    },
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'POST',
    url: `/communities/${id}/competitors`,
  });
};

const communitiesIDPreleaseAndOccupancyWeekYearGet = (token, id, year, week) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      timestamp: getUnixTime(new Date()),
    },
    url: `/communities/${id}/prelease-and-occupancy/${year}/${week}`,
  });
};

const communitiesIDPreleaseAndOccupancyWeekYearPost = (token, id, year, week, preleaseAndOccupancy) => {
  return axios.request({
    data: {
      prelease_and_occupancy: preleaseAndOccupancy,
    },
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'POST',
    url: `/communities/${id}/prelease-and-occupancy/${year}/${week}`,
  });
};

const communitiesIDPreleaseAndOccupancyYearWeeklyGoalsGet = (token, id, year) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      timestamp: getUnixTime(new Date()),
    },
    url: `/communities/${id}/prelease-and-occupancy/${year}/weekly-goals`,
  });
};

const communitiesIDPreleaseAndOccupancyYearWeeklyGoalsPost = (token, id, year, values) => {
  return axios.request({
    data: values,
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'POST',
    url: `/communities/${id}/prelease-and-occupancy/${year}/weekly-goals`,
  });
};

const competitorsGet = (
  token,
  classification,
  keywords,
  community_id,
  orderByColumn,
  orderByDirection,
  limit,
  offset,
) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    params: {
      classification: classification,
      keywords: keywords,
      community_id: community_id,
      order_by_column: orderByColumn,
      order_by_direction: orderByDirection,
      limit: limit,
      offset: offset,
      timestamp: getUnixTime(new Date()),
    },
    url: '/competitors',
  });
};

const faqGet = (token) => {
  return axios.request({
    headers: {
      Authorization: `JWT ${token}`,
    },
    method: 'GET',
    url: '/faq',
  });
};

export {
  communitiesGet,
  communitiesIDClonePost,
  communitiesIDCommunityGet,
  communitiesIDCommunityPut,
  communitiesIDCompetitorsGet,
  communitiesIDCompetitorsPost,
  communitiesIDDeletePost,
  communitiesIDFeesGet,
  communitiesIDFeesPut,
  communitiesIDPreleaseAndOccupancyWeekYearGet,
  communitiesIDPreleaseAndOccupancyWeekYearPost,
  communitiesIDPreleaseAndOccupancyYearWeeklyGoalsGet,
  communitiesIDPreleaseAndOccupancyYearWeeklyGoalsPost,
  communitiesIDUnitMixDetailsWeekYearGet,
  communitiesIDUnitMixDetailsWeekYearPost,
  communitiesIDUtilitiesAndFeaturesGet,
  communitiesIDUtilitiesAndFeaturesPut,
  communitiesPost,
  competitorsGet,
  faqGet,
};
