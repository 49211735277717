const ATTACHED_WRS_INDEX = `
- Leasing Performance
- Pre-lease Report
- Market Survey
- Comparable Standardization Tables
- Box Score
- A/R - Current
- A/R - Past
- A/R - Commercial
`.trim();

const CLASSES = ['A+', 'A', 'B+', 'B', 'C+', 'C', 'D'];

const COMPETITOR_REVIEW = '';

const CONCESSION_TYPES = ['Free Rent', 'Gift Card', 'Tangible'];

const IMPACTS = ['Low', 'Medium', 'High'];

const LEASING_NUMBERS = '';

const MANAGEMENTS = [
  'Alliance Residential',
  'American Campus Communities',
  'Apartment Management Consultants',
  'Asset Campus Housing',
  'BH Management',
  'Campus Advantage',
  'Capstone Collegiate Communities',
  'CLS',
  'COCM',
  'Core Spaces',
  'Equity Residential',
  'FPI Management',
  'Greystar',
  'Landmark Properties',
  'Lincoln Property Company',
  'MAA',
  'Other',
  'Peak Campus',
  'Pinnacle Campus Living',
  'The Colliers Companies',
  'The Preiss Company',
  'The Scion Group',
  'WinnCompanies',
];

const MARKETING_AND_RESIDENT_EVENTS = `
- Specials/Incentives:
- Marketing Efforts This Week:
- Planned Resident Events:
- Online Reviews:
    - Monthly Goal:
    - Current Monthly Reviews:
`.trim();

const PRICING_TOOLS = ['Client System (Yardi)', 'Entrata', 'LRO', 'None', 'RentMax', 'Yardi', 'YieldStar'];

const PROXIMITIES = [
  'Pedestrian',
  '0.5 mile',
  '0.75 mile',
  '1 mile',
  '1.5 miles',
  '2 miles',
  '3 miles',
  '4 miles',
  '4+ miles',
];

const RESPONSIBILITIES = ['Bill Back', 'Cap', 'Directly to provider', 'Flat Fee', 'Included'];

const STATES = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Federated States Of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

const STYLES = ['Cottage', 'Townhome', 'Garden', 'Lowrise', 'Midrise', 'Highrise'];

const TYPES = ['Conventional', 'Student'];

const WAIVING_FEES = ['Admin Fee', 'Application', 'Deposit', 'Pet Deposit'];

export {
  ATTACHED_WRS_INDEX,
  CLASSES,
  COMPETITOR_REVIEW,
  CONCESSION_TYPES,
  IMPACTS,
  LEASING_NUMBERS,
  MANAGEMENTS,
  MARKETING_AND_RESIDENT_EVENTS,
  PRICING_TOOLS,
  PROXIMITIES,
  RESPONSIBILITIES,
  STATES,
  STYLES,
  TYPES,
  WAIVING_FEES,
};
