import React from 'react';
import {Redirect} from 'react-router-dom';

import {Context, Consumer} from './securities';

const withContext = (Component, Boundary, Layout) => {
  return class Class extends React.Component {
    static contextType = Context;

    componentDidMount = () => {
      this.component();
    };

    componentDidUpdate = () => {
      this.component();
    };

    component = () => {
      if (Boundary === 'Private') {
        if (this.context.isValidB()) {
          this.context.refresh();
        } else {
          this.context.signOut(this.props.history.push);
        }
      }
    };

    render = () => {
      return <Consumer>{this.renderConsumer}</Consumer>;
    };

    renderConsumer = (context) => {
      if (Boundary === 'Private') {
        if (!context.isValidA() && !context.isValidB()) {
          window.location.href = '/api/sign-in';
          return null;
        }
      }
      if (Boundary === 'Public') {
        if (context.isValidA() && context.isValidB()) {
          return <Redirect to="/" />;
        }
      }
      return (
        <Layout context={context}>
          <Component {...this.props} context={context} />
        </Layout>
      );
    };
  };
};

export {withContext};
