import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding} from '@fortawesome/free-solid-svg-icons/faBuilding';
import {faCalendarWeek} from '@fortawesome/free-solid-svg-icons/faCalendarWeek';
import {faCheckDouble} from '@fortawesome/free-solid-svg-icons/faCheckDouble';
import {faCity} from '@fortawesome/free-solid-svg-icons/faCity';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import {faHotel} from '@fortawesome/free-solid-svg-icons/faHotel';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {faSortAlphaDown} from '@fortawesome/free-solid-svg-icons/faSortAlphaDown';
import {faSortAlphaUp} from '@fortawesome/free-solid-svg-icons/faSortAlphaUp';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import _ from 'lodash';
import React from 'react';
import ReactPaginate from 'react-paginate';
import {NavLink} from 'react-router-dom';

import {buildTimestamp} from '../builders';
import {withContext} from '../decorators';
import {Layout as Large} from '../layouts/large';
import {competitorsGet} from '../requests';

class Route extends React.Component {
  state = {
    //
    classification: '',
    keywords: '',
    community_id: '',
    orderBy: {column: 'communities.name', direction: 'asc'},
    limit: 10,
    offset: 0,
    //
    loading: false,
    exception: null,
    options: [],
    count: 0,
    communities: [],
  };

  componentDidMount = () => {
    this.request();
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    competitorsGet(
      this.props.context.token,
      this.state.classification,
      this.state.keywords,
      this.state.community_id,
      this.state.orderBy.column,
      this.state.orderBy.direction,
      this.state.limit,
      this.state.offset,
    ).then(
      (response) => {
        this.setState({
          loading: false,
          exception: null,
          options: response.data.options,
          count: response.data.count,
          communities: response.data.communities,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          options: [],
          count: 0,
          communities: [],
        });
      },
    );
  };

  handleKeywords = (event) => {
    this.setState({keywords: event.target.value, offset: 0}, () => this.request());
  };

  handleCommunityID = (event) => {
    this.setState({community_id: event.target.value, offset: 0}, () => this.request());
  };

  handleClassification = (classification) => {
    this.setState({classification: classification, offset: 0}, () => this.request());
  };

  handleOrderBy = (column) => {
    let direction = 'asc';
    if (this.state.orderBy.column === column) {
      if (this.state.orderBy.direction === 'asc') {
        direction = 'desc';
      }
    }
    this.setState({orderBy: {column, direction}, offset: 0}, () => this.request());
  };

  handlePageChange = (data) => {
    this.setState({offset: Math.ceil(data.selected * this.state.limit)}, () => this.request());
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faCity} />
          Weekly Updates
        </h1>
        {this.renderFilters()}
        <div className="row">
          <div className="col-12">
            {this.renderLoading()}
            {this.renderException()}
            {this.renderTable()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderFilters = () => {
    return (
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <div className="align-items-center d-flex mb-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon fixedWidth icon={faSearch} />
                </div>
              </div>
              <input
                className="form-control"
                onChange={this.handleKeywords}
                type="text"
                defaultValue={this.state.keywords}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-3">
          <div className="align-items-center d-flex mb-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <div className="input-group-text">Show competitors of</div>
              </div>
              <select
                className="custom-select"
                defaultValue={this.state.community_id}
                onChange={this.handleCommunityID}
              >
                <option value="">All</option>
                {_.map(this.state.options, (option) => {
                  return (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-2">
          <a
            className={
              this.state.classification === ''
                ? 'btn btn-block btn-primary mb-3 text-white'
                : 'btn btn-block btn-outline-primary mb-3 text-primary'
            }
            onClick={() => this.handleClassification('')}
          >
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faCheckDouble} />
            Show All
          </a>
        </div>
        <div className="col-sm-12 col-md-2">
          <a
            className={
              this.state.classification === 'Cardinal Community'
                ? 'btn btn-block btn-primary mb-3 text-white'
                : 'btn btn-block btn-outline-primary mb-3 text-body'
            }
            onClick={() => this.handleClassification('Cardinal Community')}
          >
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faBuilding} />
            Show Only Cardinal
          </a>
        </div>
        <div className="col-sm-12 col-md-2">
          <a
            className={
              this.state.classification === 'Non-Cardinal Community'
                ? 'btn btn-block btn-primary mb-3 text-white'
                : 'btn btn-block btn-outline-primary mb-3 text-body'
            }
            onClick={() => this.handleClassification('Non-Cardinal Community')}
          >
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faHotel} />
            Show Only Non-Cardinal
          </a>
        </div>
      </div>
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return (
      <div className="alert alert-danger mb-3">
        <p className="mb-0">
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faExclamationCircle} />
          {this.state.exception}
        </p>
      </div>
    );
  };

  renderTable = () => {
    if (this.state.loading) {
      return null;
    }
    if (this.state.exception) {
      return null;
    }
    if (!this.state.communities.length) {
      return (
        <div className="alert alert-danger">
          <p className="mb-0">
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faExclamationCircle} />
            Not finding the Competitor you&#39;re looking for? Log a Dashboard Helpdesk ticket under the label of
            &#34;Terrain&#34;.
          </p>
        </div>
      );
    }
    const {communities, limit, offset} = this.state;
    return (
      <React.Fragment>
        <table className="table table-bordered table-hover table-sm table-responsive-sm">
          <thead className="thead-dark">
            <tr>{this.renderTHs()}</tr>
          </thead>
          <tbody>
            {_.map(communities, (community) => {
              return <tr key={community.id}>{this.renderTDs(community)}</tr>;
            })}
          </tbody>
        </table>
        <div className="align-items-center d-flex flex-column flex-lg-row">
          <p className="d-flex flex-fill justify-content-start">
            Show {offset + 1} to {Math.min(this.state.count, offset + limit)} of {this.state.count} competitors
          </p>
          <div className="d-flex flex-fill justify-content-end">
            <ReactPaginate
              activeClassName={'active'}
              activeLinkClassName={'active'}
              breakClassName={'page-item'}
              breakLinkClassName={'cursor-pointer page-link'}
              breakLabel={'...'}
              containerClassName={'justify-content-center pagination'}
              marginPagesDisplayed={0}
              nextClassName={'page-item'}
              nextLabel={'>'}
              forcePage={this.state.offset / this.state.limit}
              nextLinkClassName={'cursor-pointer page-link'}
              onPageChange={this.handlePageChange}
              pageClassName={'page-item'}
              pageCount={Math.ceil(this.state.count / this.state.limit)}
              pageLinkClassName={'cursor-pointer page-link'}
              pageRangeDisplayed={5}
              previousClassName={'page-item'}
              previousLabel={'<'}
              previousLinkClassName={'cursor-pointer page-link'}
              subContainerClassName={'pagination'}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderTHs = () => {
    return (
      <React.Fragment>
        <th className="w-100" onClick={() => this.handleOrderBy('communities.name')}>
          Name
          {this.renderIcon('name')}
        </th>
        <th onClick={() => this.handleOrderBy('communities.property_code')}>
          Property Code
          {this.renderIcon('property_code')}
        </th>
        <th onClick={() => this.handleOrderBy('communities.group_')}>
          Group
          {this.renderIcon('group_')}
        </th>
        <th onClick={() => this.handleOrderBy('communities.cell')}>
          Cell
          {this.renderIcon('cell')}
        </th>
        <th onClick={() => this.handleOrderBy('communities.market')}>
          Market
          {this.renderIcon('market')}
        </th>
        <th onClick={() => this.handleOrderBy('unit_mix_details')}>
          Unit Mix Details Last Updated {this.renderIcon('unit_mix_details')}
        </th>
        <th onClick={() => this.handleOrderBy('preleases_and_occupancies')}>
          Prelease &amp; Occupancy Last Updated {this.renderIcon('preleases_and_occupancies')}
        </th>
        <th className="actions">Actions</th>
      </React.Fragment>
    );
  };

  renderTDs = (community) => {
    if (community.classification === 'Cardinal Community') {
      return (
        <React.Fragment>
          <td className="w-100">{community.name}</td>
          <td>{community.property_code}</td>
          <td>{community.group}</td>
          <td>{community.cell}</td>
          <td>{community.market}</td>
          <td>{buildTimestamp(community.unit_mix_details)}</td>
          <td>{buildTimestamp(community.preleases_and_occupancies)}</td>
          <td className="actions">
            <NavLink
              className="btn btn-primary btn-sm"
              to={`/cardinal-communities/${community.id}/weekly-updates/unit-mix-details`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
              Weekly Updates
            </NavLink>
          </td>
        </React.Fragment>
      );
    }
    if (community.classification === 'Non-Cardinal Community') {
      return (
        <React.Fragment>
          <td className="w-100">{community.name}</td>
          <td></td>
          <td></td>
          <td></td>
          <td>{community.market}</td>
          <td>{buildTimestamp(community.unit_mix_details)}</td>
          <td>{buildTimestamp(community.preleases_and_occupancies)}</td>
          <td className="actions">
            <NavLink
              className="btn btn-primary btn-sm mr-1"
              to={`/non-cardinal-communities/${community.id}/weekly-updates/unit-mix-details`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
              Weekly Updates
            </NavLink>
          </td>
        </React.Fragment>
      );
    }
    return null;
  };

  renderIcon = (column) => {
    const {orderBy} = this.state;
    if (column !== orderBy.column) {
      return null;
    }
    if (orderBy.direction === 'asc') {
      return <FontAwesomeIcon className="ml-2" fixedWidth icon={faSortAlphaUp} />;
    }
    if (orderBy.direction === 'desc') {
      return <FontAwesomeIcon className="ml-2" fixedWidth icon={faSortAlphaDown} />;
    }
    return null;
  };
}

Route = withContext(Route, 'Private', Large);

export {Route};
