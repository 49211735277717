import {Form as F, Formik} from 'formik';
import React from 'react';
import * as yup from 'yup';

import {buildCancel, buildSave, buildSaveAndNext, buildTimestamp} from '../builders';
import {Slot as Group} from '../slots/group';
import {Slot as Prompt} from '../slots/prompt';

class Form extends React.Component {
  render = () => {
    return (
      <Formik
        initialValues={this.buildInitialValues()}
        onSubmit={this.props.handleSubmit}
        render={this.buildRender}
        validationSchema={this.buildValidationSchema()}
      />
    );
  };

  buildDisabled = () => {
    if (['HQ', 'CM'].indexOf(this.props.context.type) === -1) {
      return true;
    }
    return false;
  };

  buildInitialValues = () => {
    if (this.props.community === null) {
      return {
        application_fee: 0.0,
        administration_fee: 0.0,
        pet_fee: 0.0,
        pet_rent: 0.0,
        pet_deposit: 0.0,
        uncovered_parking: 0.0,
        covered_parking: 0.0,
        garage: 0.0,
        tandem_parking: 0.0,
        assigned_parking: 0.0,
        unassigned_parking: 0.0,
        average_storage: 0.0,
        flat_utility_fee: 0.0,
        furniture_fee: 0.0,
        double_occupancy: 0.0,
        green_fee: 0.0,
        short_term_premium: 0.0,
        early_move_in_fee: 0.0,
        premium: 0.0,
        other_fees_name: '',
        other_fees_amount: 0.0,
      };
    }
    return {
      application_fee: this.props.community.application_fee || 0.0,
      administration_fee: this.props.community.administration_fee || 0.0,
      pet_fee: this.props.community.pet_fee || 0.0,
      pet_rent: this.props.community.pet_rent || 0.0,
      pet_deposit: this.props.community.pet_deposit || 0.0,
      uncovered_parking: this.props.community.uncovered_parking || 0.0,
      covered_parking: this.props.community.covered_parking || 0.0,
      garage: this.props.community.garage || 0.0,
      tandem_parking: this.props.community.tandem_parking || 0.0,
      assigned_parking: this.props.community.assigned_parking || 0.0,
      unassigned_parking: this.props.community.unassigned_parking || 0.0,
      average_storage: this.props.community.average_storage || 0.0,
      flat_utility_fee: this.props.community.flat_utility_fee || 0.0,
      furniture_fee: this.props.community.furniture_fee || 0.0,
      double_occupancy: this.props.community.double_occupancy || 0.0,
      green_fee: this.props.community.green_fee || 0.0,
      short_term_premium: this.props.community.short_term_premium || 0.0,
      early_move_in_fee: this.props.community.early_move_in_fee || 0.0,
      premium: this.props.community.premium || 0.0,
      other_fees_name: this.props.community.other_fees_name || '',
      other_fees_amount: this.props.community.other_fees_amount || 0.0,
    };
  };

  buildLabel = (label) => {
    if (this.props.community.type === 'Conventional') {
      return `${label} Per Unit`;
    }
    if (this.props.community.type === 'Student') {
      return `${label} Per Bed`;
    }
    return label;
  };

  buildRender = ({dirty, errors, isSubmitting, setFieldValue, submitCount, submitForm, touched}) => {
    return (
      <F>
        <Prompt dirty={dirty} submitCount={submitCount} />
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['application_fee']}
              label="Application Fee Per Person"
              min="0"
              name="application_fee"
              step="0.01"
              touched={touched['application_fee']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['administration_fee']}
              label={this.buildLabel('Administration Fee')}
              min="0"
              name="administration_fee"
              step="0.01"
              touched={touched['administration_fee']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['pet_fee']}
              label="Pet Fee"
              min="0"
              name="pet_fee"
              step="0.01"
              touched={touched['pet_fee']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['pet_rent']}
              label="Pet Rent"
              min="0"
              name="pet_rent"
              step="0.01"
              touched={touched['pet_rent']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['pet_deposit']}
              label="Pet Deposit"
              min="0"
              name="pet_deposit"
              step="0.01"
              touched={touched['pet_deposit']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['uncovered_parking']}
              label="Uncovered Parking"
              min="0"
              name="uncovered_parking"
              step="0.01"
              touched={touched['uncovered_parking']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['covered_parking']}
              label="Covered Parking"
              min="0"
              name="covered_parking"
              step="0.01"
              touched={touched['covered_parking']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['garage']}
              label="Garage"
              min="0"
              name="garage"
              step="0.01"
              touched={touched['garage']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['tandem_parking']}
              label="Tandem Parking"
              min="0"
              name="tandem_parking"
              step="0.01"
              touched={touched['tandem_parking']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['assigned_parking']}
              label="Assigned Parking"
              min="0"
              name="assigned_parking"
              step="0.01"
              touched={touched['assigned_parking']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['unassigned_parking']}
              label="Unassigned Parking"
              min="0"
              name="unassigned_parking"
              step="0.01"
              touched={touched['unassigned_parking']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['average_storage']}
              label="Average Storage"
              min="0"
              name="average_storage"
              step="0.01"
              touched={touched['average_storage']}
              type="number"
            />
          </div>
          {/*
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['flat_utility_fee']}
              label={this.buildLabel('Flat Utility Fee')}
              min="0"
              name="flat_utility_fee"
              step="0.01"
              touched={touched['flat_utility_fee']}
              type="number"
            />
          </div>
          */}
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['furniture_fee']}
              label={this.buildLabel('Furniture Fee')}
              min="0"
              name="furniture_fee"
              step="0.01"
              touched={touched['furniture_fee']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input-dollar"
              disabled={this.buildDisabled()}
              errors={errors['double_occupancy']}
              label="Double Occupancy"
              min="0"
              name="double_occupancy"
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                setFieldValue('double_occupancy', value ? value : 0.0);
              }}
              step="0.01"
              touched={touched['double_occupancy']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input-dollar"
              disabled={this.buildDisabled()}
              errors={errors['green_fee']}
              label="Green Fee"
              min="0"
              name="green_fee"
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                setFieldValue('green_fee', value ? value : 0.0);
              }}
              step="0.01"
              touched={touched['green_fee']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input-dollar"
              disabled={this.buildDisabled()}
              errors={errors['short_term_premium']}
              label="Short Term Premium"
              min="0"
              name="short_term_premium"
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                setFieldValue('short_term_premium', value ? value : 0.0);
              }}
              step="0.01"
              touched={touched['short_term_premium']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input-dollar"
              disabled={this.buildDisabled()}
              errors={errors['early_move_in_fee']}
              label="Early Move-In Fee"
              min="0"
              name="early_move_in_fee"
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                setFieldValue('early_move_in_fee', value ? value : 0.0);
              }}
              step="0.01"
              touched={touched['early_move_in_fee']}
              type="number"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <Group
              component="input"
              disabled={this.buildDisabled()}
              errors={errors['premium']}
              label="Premium"
              min="0"
              name="premium"
              step="0.01"
              touched={touched['premium']}
              type="number"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="card mb-3">
              <div className="card-header">Other Fees</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['other_fees_name']}
                      label="Name"
                      name="other_fees_name"
                      touched={touched['other_fees_name']}
                      type="text"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input-dollar"
                      disabled={this.buildDisabled()}
                      errors={errors['other_fees_amount']}
                      label="Amount"
                      min="0"
                      name="other_fees_amount"
                      onChange={(event) => {
                        const value = parseFloat(event.target.value);
                        setFieldValue('other_fees_amount', value ? value : 0.0);
                      }}
                      step="0.01"
                      touched={touched['other_fees_amount']}
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <p className="align-items-center d-flex flex-fill justify-content-start mb-3">
            Last Update:
            <strong className="ml-2">{buildTimestamp(this.props.community.fees_updated_at)}</strong>
          </p>
          <div className="d-flex flex-fill justify-content-end mb-3">
            {buildCancel(() => this.props.history.goBack())}
            {buildSave(errors, isSubmitting, setFieldValue, submitForm)}
            {buildSaveAndNext(errors, isSubmitting, setFieldValue, submitForm)}
          </div>
        </div>
      </F>
    );
  };

  buildValidationSchema = () => {
    return yup.object().shape({
      application_fee: yup.number().required(),
      administration_fee: yup.number().required(),
      pet_fee: yup.number().required(),
      pet_rent: yup.number().required(),
      pet_deposit: yup.number().required(),
      average_storage: yup.number().required(),
      uncovered_parking: yup.number().required(),
      covered_parking: yup.number().required(),
      garage: yup.number().required(),
      tandem_parking: yup.number().required(),
      assigned_parking: yup.number().required(),
      unassigned_parking: yup.number().required(),
      flat_utility_fee: yup.number().required(),
      furniture_fee: yup.number().required(),
      premium: yup.number().required(),
    });
  };
}

export {Form};
