import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import _ from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';

import {buildTitle} from '../builders';
import {withContext} from '../decorators';
import {Form} from '../forms/weekly-goals';
import {Layout as Large} from '../layouts/large';
import {Modal as Confirm} from '../modals/confirm';
import {
  communitiesIDPreleaseAndOccupancyYearWeeklyGoalsGet,
  communitiesIDPreleaseAndOccupancyYearWeeklyGoalsPost,
} from '../requests';
import {Slot as Tabs} from '../slots/tabs';

class Route extends React.Component {
  state = {
    loading: false,
    exception: null,
    year: new Date().getFullYear(),
    community: null,
    preleases_and_occupancies: null,
    items: null,
    next_year: false,
    previous_year: false,
  };

  componentDidMount = () => {
    this.request();
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    communitiesIDPreleaseAndOccupancyYearWeeklyGoalsGet(
      this.props.context.token,
      this.props.match.params.id,
      this.state.year,
    ).then(
      (response) => {
        const {community, preleases_and_occupancies, items} = this.buildState(response.data);
        this.setState({
          loading: false,
          exception: null,
          community: community,
          preleases_and_occupancies: preleases_and_occupancies,
          items: items,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          community: null,
          preleases_and_occupancies: null,
          items: null,
        });
      },
    );
  };

  handlePrevious = () => {
    this.setState({
      previous_year: true,
      next_year: false,
    });
  };

  handlePreviousYes = () => {
    this.setState(
      {
        previous_year: false,
        next_year: false,
      },
      () => {
        this.setState(
          {
            year: this.state.year - 1,
          },
          () => this.request(),
        );
      },
    );
  };

  handlePreviousNo = () => {
    this.setState({
      next_year: false,
      previous_year: false,
    });
  };

  handleNext = () => {
    this.setState({
      previous_year: false,
      next_year: true,
    });
  };

  handleNextYes = () => {
    this.setState(
      {
        previous_year: false,
        next_year: false,
      },
      () => {
        this.setState(
          {
            year: this.state.year + 1,
          },
          () => this.request(),
        );
      },
    );
  };

  handleNextNo = () => {
    this.setState({
      next_year: false,
      previous_year: false,
    });
  };

  handleSubmit = (values, {setSubmitting}) => {
    communitiesIDPreleaseAndOccupancyYearWeeklyGoalsPost(
      this.props.context.token,
      this.props.match.params.id,
      this.state.year,
      _.map([..._.cloneDeep(values.items)], this.decode),
    ).then(
      (response) => {
        setSubmitting(false);
        toast.success(`${response.data.community.name} was saved successfully`);
        if (values.redirect) {
          this.props.history.push(`${this.props.url(response.data.community)}${this.props.next}`);
        } else {
          const {community, preleases_and_occupancies, items} = this.buildState(response.data);
          this.setState({
            community: community,
            preleases_and_occupancies: preleases_and_occupancies,
            items: items,
          });
        }
      },
      (error) => {
        setSubmitting(false);
        toast.error(error.response.data.error);
      },
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">
          {buildTitle(this.props.classification, this.state.community ? [this.state.community.name, 'Edit'] : [])}
        </h1>
        {this.renderTabs()}
        {this.renderLoading()}
        {this.renderException()}
        {this.renderModal()}
        {this.renderForm()}
      </React.Fragment>
    );
  };

  renderTabs = () => {
    return (
      <Tabs
        classification={this.props.classification}
        match={this.props.match}
        one={this.props.one}
        two={this.props.two}
        url={this.props.url}
      />
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return (
      <div className="alert alert-danger">
        <p className="mb-0">{this.state.exception}</p>
      </div>
    );
  };

  renderModal = () => {
    if (this.state.previous_year) {
      return (
        <Confirm
          handleNo={this.handlePreviousNo}
          handleYes={this.handlePreviousYes}
          message="Are you sure you want to edit the numbers of the previous year?"
        />
      );
    }
    if (this.state.next_year) {
      return (
        <Confirm
          handleNo={this.handleNextNo}
          handleYes={this.handleNextYes}
          message="Are you sure you want to edit the numbers of the next year?"
        />
      );
    }
    return null;
  };

  renderForm = () => {
    if (this.state.loading) {
      return null;
    }
    if (this.state.exception) {
      return null;
    }
    if (this.state.community === null) {
      return null;
    }
    return (
      <Form
        classification={this.props.classification}
        community={this.state.community}
        context={this.props.context}
        handlePrevious={this.handlePrevious}
        handleNext={this.handleNext}
        handleSubmit={this.handleSubmit}
        history={this.props.history}
        items={this.state.items}
        preleases_and_occupancies={this.state.preleases_and_occupancies}
        year={this.state.year}
      />
    );
  };

  buildState = (data) => {
    return {
      community: data.community,
      preleases_and_occupancies: data.preleases_and_occupancies,
      items: data.items,
    };
  };
}

Route = withContext(Route, 'Private', Large);
Route = withRouter(Route);

export {Route};
