import {Form as F, Formik} from 'formik';
import React from 'react';
import * as yup from 'yup';

import {buildCancel, buildSave, buildSaveAndNext, buildTimestamp} from '../builders';
import {RESPONSIBILITIES} from '../constants';
import {Slot as Group} from '../slots/group';
import {Slot as Prompt} from '../slots/prompt';

class Form extends React.Component {
  render = () => {
    return (
      <Formik
        initialValues={this.buildInitialValues()}
        onSubmit={this.props.handleSubmit}
        render={this.buildRender}
        validationSchema={this.buildValidationSchema()}
      />
    );
  };

  buildDisabled = () => {
    if (['HQ', 'CM'].indexOf(this.props.context.type) === -1) {
      return true;
    }
    return false;
  };

  buildInitialValues = () => {
    if (this.props.community === null) {
      return {
        electricity_amount: 0.0,
        electricity_responsibility: '',
        gas_amount: 0.0,
        gas_responsibility: '',
        water_amount: 0.0,
        water_responsibility: '',
        sewer_amount: 0.0,
        sewer_responsibility: '',
        trash_amount: 0.0,
        trash_responsibility: '',
        cable_amount: 0.0,
        cable_responsibility: '',
        internet_amount: 0.0,
        internet_responsibility: '',
        furniture_amount: 0.0,
        furniture_responsibility: '',
        basketball: false,
        bbq_grills: false,
        bike_storage: false,
        business_center: false,
        clubhouse: false,
        controlled_gated_access: false,
        dog_park: false,
        electric_car_charging_stations: false,
        fitness_center: false,
        furnished: false,
        game_room_lounge: false,
        in_unit_smart_technology: false,
        lake: false,
        media_center_theater_room: false,
        on_transit_line: false,
        onsite_retail: false,
        pet_friendly: false,
        pet_wash: false,
        pool_spa: false,
        private_balconies: false,
        recording_studio: false,
        storage_units: false,
        tanning: false,
        valet_trash: false,
        volleyball: false,
      };
    }
    return {
      electricity_amount: this.props.community.electricity_amount || 0.0,
      electricity_responsibility: this.props.community.electricity_responsibility || '',
      gas_amount: this.props.community.gas_amount || 0.0,
      gas_responsibility: this.props.community.gas_responsibility || '',
      water_amount: this.props.community.water_amount || 0.0,
      water_responsibility: this.props.community.water_responsibility || '',
      sewer_amount: this.props.community.sewer_amount || 0.0,
      sewer_responsibility: this.props.community.sewer_responsibility || '',
      trash_amount: this.props.community.trash_amount || 0.0,
      trash_responsibility: this.props.community.trash_responsibility || '',
      cable_amount: this.props.community.cable_amount || 0.0,
      cable_responsibility: this.props.community.cable_responsibility || '',
      internet_amount: this.props.community.internet_amount || 0.0,
      internet_responsibility: this.props.community.internet_responsibility || '',
      furniture_amount: this.props.community.furniture_amount || 0.0,
      furniture_responsibility: this.props.community.furniture_responsibility || '',
      basketball: this.props.community.basketball || false,
      bbq_grills: this.props.community.bbq_grills || false,
      bike_storage: this.props.community.bike_storage || false,
      business_center: this.props.community.business_center || false,
      clubhouse: this.props.community.clubhouse || false,
      controlled_gated_access: this.props.community.controlled_gated_access || false,
      dog_park: this.props.community.dog_park || false,
      electric_car_charging_stations: this.props.community.electric_car_charging_stations || false,
      fitness_center: this.props.community.fitness_center || false,
      furnished: this.props.community.furnished || false,
      game_room_lounge: this.props.community.game_room_lounge || false,
      in_unit_smart_technology: this.props.community.in_unit_smart_technology || false,
      lake: this.props.community.lake || false,
      media_center_theater_room: this.props.community.media_center_theater_room || false,
      on_transit_line: this.props.community.on_transit_line || false,
      onsite_retail: this.props.community.onsite_retail || false,
      pet_friendly: this.props.community.pet_friendly || false,
      pet_wash: this.props.community.pet_wash || false,
      pool_spa: this.props.community.pool_spa || false,
      private_balconies: this.props.community.private_balconies || false,
      recording_studio: this.props.community.recording_studio || false,
      storage_units: this.props.community.storage_units || false,
      tanning: this.props.community.tanning || false,
      valet_trash: this.props.community.valet_trash || false,
      volleyball: this.props.community.volleyball || false,
    };
  };

  buildLabel = (label) => {
    if (this.props.community.type === 'Conventional') {
      return `${label} Per Unit`;
    }
    if (this.props.community.type === 'Student') {
      return `${label} Per Bed`;
    }
    return label;
  };

  buildRender = ({dirty, errors, isSubmitting, setFieldValue, submitCount, submitForm, touched, values}) => {
    return (
      <F>
        <Prompt dirty={dirty} submitCount={submitCount} />
        <h2 className="mb-3">Services</h2>
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Electricity</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['electricity_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="electricity_amount"
                      step="0.01"
                      touched={touched['electricity_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['electricity_responsibility']}
                      label="Responsible for paying"
                      name="electricity_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['electricity_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Gas</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input-dollar"
                      disabled={this.buildDisabled()}
                      errors={errors['gas_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="gas_amount"
                      onChange={(event) => {
                        const value = parseFloat(event.target.value);
                        setFieldValue('gas_amount', value ? value : 0.0);
                      }}
                      step="0.01"
                      touched={touched['gas_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['gas_responsibility']}
                      label="Responsible for paying"
                      name="gas_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['gas_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Water</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['water_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="water_amount"
                      step="0.01"
                      touched={touched['water_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['water_responsibility']}
                      label="Responsible for paying"
                      name="water_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['water_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Sewer</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['sewer_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="sewer_amount"
                      step="0.01"
                      touched={touched['sewer_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['sewer_responsibility']}
                      label="Responsible for paying"
                      name="sewer_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['sewer_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Trash</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['trash_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="trash_amount"
                      step="0.01"
                      touched={touched['trash_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['trash_responsibility']}
                      label="Responsible for paying"
                      name="trash_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['trash_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Cable</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['cable_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="cable_amount"
                      step="0.01"
                      touched={touched['cable_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['cable_responsibility']}
                      label="Responsible for paying"
                      name="cable_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['cable_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Internet</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['internet_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="internet_amount"
                      step="0.01"
                      touched={touched['internet_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['internet_responsibility']}
                      label="Responsible for paying"
                      name="internet_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['internet_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <div className="card mb-3">
              <div className="card-header">Furniture</div>
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="input"
                      disabled={this.buildDisabled()}
                      errors={errors['furniture_amount']}
                      label={this.buildLabel('Monthly Amount')}
                      min="0"
                      name="furniture_amount"
                      step="0.01"
                      touched={touched['furniture_amount']}
                      type="number"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Group
                      component="select-one"
                      disabled={this.buildDisabled()}
                      errors={errors['furniture_responsibility']}
                      label="Responsible for paying"
                      name="furniture_responsibility"
                      options={RESPONSIBILITIES}
                      touched={touched['furniture_responsibility']}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2>Amenities</h2>
        <hr />
        <div className="row">
          <div className="col-sm-12">
            <Group
              checked={values['basketball']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['basketball']}
              label="Basketball"
              name="basketball"
              touched={touched['basketball']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['bbq_grills']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['bbq_grills']}
              label="BBQ Grills"
              name="bbq_grills"
              touched={touched['bbq_grills']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['bike_storage']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['bike_storage']}
              label="Bike Storage"
              name="bike_storage"
              touched={touched['bike_storage']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['business_center']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['business_center']}
              label="Business Center"
              name="business_center"
              touched={touched['business_center']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['clubhouse']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['clubhouse']}
              label="Clubhouse"
              name="clubhouse"
              touched={touched['clubhouse']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['controlled_gated_access']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['controlled_gated_access']}
              label="Controlled/Gated Access"
              name="controlled_gated_access"
              touched={touched['controlled_gated_access']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['dog_park']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['dog_park']}
              label="Dog Park"
              name="dog_park"
              touched={touched['dog_park']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['electric_car_charging_stations']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['electric_car_charging_stations']}
              label="Electric Car Charging Stations"
              name="electric_car_charging_stations"
              touched={touched['electric_car_charging_stations']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['fitness_center']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['fitness_center']}
              label="Fitness Center	"
              name="fitness_center"
              touched={touched['fitness_center']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['furnished']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['furnished']}
              label="Furnished"
              name="furnished"
              touched={touched['furnished']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['game_room_lounge']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['game_room_lounge']}
              label="Game Room/Lounge"
              name="game_room_lounge"
              touched={touched['game_room_lounge']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['in_unit_smart_technology']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['in_unit_smart_technology']}
              label="In-Unit Smart Technology"
              name="in_unit_smart_technology"
              touched={touched['in_unit_smart_technology']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['lake']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['lake']}
              label="Lake"
              name="lake"
              touched={touched['lake']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['media_center_theater_room']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['media_center_theater_room']}
              label="Media Center/Theater Room"
              name="media_center_theater_room"
              touched={touched['media_center_theater_room']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['on_transit_line']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['on_transit_line']}
              label="On Transit Line"
              name="on_transit_line"
              touched={touched['on_transit_line']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['onsite_retail']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['onsite_retail']}
              label="Onsite Retail"
              name="onsite_retail"
              touched={touched['onsite_retail']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['pet_friendly']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['pet_friendly']}
              label="Pet Friendly	"
              name="pet_friendly"
              touched={touched['pet_friendly']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['pet_wash']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['pet_wash']}
              label="Pet Wash "
              name="pet_wash"
              touched={touched['pet_wash']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['pool_spa']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['pool_spa']}
              label="Pool/Spa"
              name="pool_spa"
              touched={touched['pool_spa']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['private_balconies']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['private_balconies']}
              label="Private Balconies "
              name="private_balconies"
              touched={touched['private_balconies']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['recording_studio']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['recording_studio']}
              label="Recording Studio "
              name="recording_studio"
              touched={touched['recording_studio']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['storage_units']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['storage_units']}
              label="Storage Units "
              name="storage_units"
              touched={touched['storage_units']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['tanning']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['tanning']}
              label="Tanning"
              name="tanning"
              touched={touched['tanning']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['valet_trash']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['valet_trash']}
              label="Valet Trash"
              name="valet_trash"
              touched={touched['valet_trash']}
            />
          </div>
          <div className="col-sm-12">
            <Group
              checked={values['volleyball']}
              component="checkbox"
              disabled={this.buildDisabled()}
              errors={errors['volleyball']}
              label="Volleyball"
              name="volleyball"
              touched={touched['volleyball']}
            />
          </div>
        </div>
        <hr />
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <p className="align-items-center d-flex flex-fill justify-content-start mb-3">
            Last Update:
            <strong className="ml-2">{buildTimestamp(this.props.community.utilities_and_features_updated_at)}</strong>
          </p>
          <div className="d-flex flex-fill justify-content-end mb-3">
            {buildCancel(() => this.props.history.goBack())}
            {buildSave(errors, isSubmitting, setFieldValue, submitForm)}
            {buildSaveAndNext(errors, isSubmitting, setFieldValue, submitForm)}
          </div>
        </div>
      </F>
    );
  };

  buildValidationSchema = () => {
    return yup.object().shape({
      electricity_amount: yup.number().required(),
      electricity_responsibility: yup.string().required(),
      water_amount: yup.number().required(),
      water_responsibility: yup.string().required(),
      sewer_amount: yup.number().required(),
      sewer_responsibility: yup.string().required(),
      trash_amount: yup.number().required(),
      trash_responsibility: yup.string().required(),
      cable_amount: yup.number().required(),
      cable_responsibility: yup.string().required(),
      internet_amount: yup.number().required(),
      internet_responsibility: yup.string().required(),
      furniture_amount: yup.number().required(),
      furniture_responsibility: yup.string().required(),
    });
  };
}

export {Form};
