import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusSquare} from '@fortawesome/free-solid-svg-icons/faMinusSquare';
import {faPlusSquare} from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import {ErrorMessage, Field, FieldArray, Form as F, Formik} from 'formik';
import _ from 'lodash';
import React from 'react';
import Select from 'react-select';
import * as yup from 'yup';

import {buildCancel, buildClassName, buildSave, buildSaveAndNext, buildTimestamp} from '../builders';
import {IMPACTS} from '../constants';
import {Slot as Prompt} from '../slots/prompt';

class Form extends React.Component {
  state = {
    competitor: {
      target_id: '',
      impact: '',
    },
  };

  render = () => {
    return (
      <Formik
        initialValues={this.buildInitialValues()}
        onSubmit={this.props.handleSubmit}
        render={this.buildRender}
        validationSchema={this.buildValidationSchema()}
      />
    );
  };

  renderPlus = (arrayHelpers) => {
    return (
      <span className="cursor float-right" onClick={() => arrayHelpers.push({...this.state.competitor})}>
        <FontAwesomeIcon fixedWidth icon={faPlusSquare} />
      </span>
    );
  };

  renderMinus = (arrayHelpers, key) => {
    return (
      <FontAwesomeIcon
        className="cursor mb-3"
        disabled={true}
        fixedWidth
        icon={faMinusSquare}
        onClick={() => arrayHelpers.remove(key)}
      />
    );
  };

  renderBlank = () => {
    return <FontAwesomeIcon className="cursor invisible mb-3" disabled={true} fixedWidth icon={faMinusSquare} />;
  };

  buildDisabled = () => {
    if (['HQ', 'CM'].indexOf(this.props.context.type) === -1) {
      return true;
    }
    return false;
  };

  buildInitialValues = () => {
    if (this.props.community === null) {
      return {
        cardinal_communities: [],
        non_cardinal_communities: [],
      };
    }
    return {
      cardinal_communities: this.props.competitorsCardinalCommunities.length
        ? this.props.competitorsCardinalCommunities
        : [],
      non_cardinal_communities: this.props.competitorsNonCardinalCommunities.length
        ? this.props.competitorsNonCardinalCommunities
        : [],
    };
  };

  buildRender = ({
    dirty,
    errors,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    submitCount,
    submitForm,
    touched,
    values,
  }) => {
    return (
      <F>
        <Prompt dirty={dirty} submitCount={submitCount} />
        <FieldArray
          name="cardinal_communities"
          render={(arrayHelpers) =>
            this.buildFieldArray(
              'Cardinal Communities',
              'cardinal_communities',
              this.props.optionsCardinalCommunities,
              arrayHelpers,
              errors,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            )
          }
        />
        <FieldArray
          name="non_cardinal_communities"
          render={(arrayHelpers) =>
            this.buildFieldArray(
              'Non-Cardinal Communities',
              'non_cardinal_communities',
              this.props.optionsNonCardinalCommunities,
              arrayHelpers,
              errors,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            )
          }
        />
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <p className="align-items-center d-flex flex-fill justify-content-start mb-3">
            Last Update:
            <strong className="ml-2">{buildTimestamp(this.props.community.competitors_updated_at)}</strong>
          </p>
          <div className="d-flex flex-fill justify-content-end mb-3">
            {buildCancel(() => this.props.history.goBack())}
            {buildSave(errors, isSubmitting, setFieldValue, submitForm)}
            {buildSaveAndNext(errors, isSubmitting, setFieldValue, submitForm)}
          </div>
        </div>
      </F>
    );
  };

  buildField = (name, options, arrayHelpers, errors, setFieldTouched, setFieldValue, touched, key, values) => {
    return (
      <React.Fragment key={key}>
        <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
          <div className=" align-items-center d-flex flex-fill justify-content-start">
            <div className="row w-100">
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <Field
                    className={`${buildClassName(
                      errors[name] && errors[name][key] && errors[name][key]['target_id'],
                      touched[name] && touched[name][key] && touched[name][key]['target_id'],
                    )}`}
                    component={Select}
                    disabled={this.buildDisabled()}
                    name={`${name}.${key}.target_id`}
                    onBlur={setFieldTouched}
                    onChange={(value) => {
                      setFieldValue(`${name}.${key}.target_id`, value.value);
                    }}
                    options={_.map(options, (o) => {
                      return {
                        key: o.id,
                        label: o.name,
                        value: o.id,
                      };
                    })}
                    value={_.filter(options, (o) => {
                      return o.id == values[name][key]['target_id'];
                    }).map((o) => {
                      return {
                        key: o.id,
                        label: o.name,
                        value: o.id,
                      };
                    })}
                  ></Field>
                  <ErrorMessage className="invalid-feedback" component="div" name={`${name}.${key}.target_id`} />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Impact</div>
                  </div>
                  <Field
                    className={`form-control ${buildClassName(
                      errors[name] && errors[name][key] && errors[name][key]['impact'],
                      touched[name] && touched[name][key] && touched[name][key]['impact'],
                    )}`}
                    component="select"
                    disabled={this.buildDisabled()}
                    name={`${name}.${key}.impact`}
                  >
                    <option value="">Select...</option>
                    {_.map(IMPACTS, (o) => {
                      return (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage className="invalid-feedback" component="div" name={`${name}.${key}.impact`} />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            {!values[name][key].target ||
            values[name][key].target.id != values[name][key].target_id ||
            ['CURRENT', 'FUTURE', 'PROJECT LOSS - SALE', 'PROJECT LOSS - TERM'].indexOf(
              values[name][key].target.status,
            ) !== -1
              ? this.renderMinus(arrayHelpers, key)
              : this.renderBlank()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  buildFieldArray = (label, name, options, arrayHelpers, errors, setFieldTouched, setFieldValue, touched, values) => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="align-items-center d-flex flex-column flex-lg-row justify-content-end mb-0">
              <label className="align-items-center d-flex flex-fill justify-content-start mb-3">{label}</label>
              <span className="d-flex flex-fill justify-content-end mb-3">{this.renderPlus(arrayHelpers)}</span>
            </div>
            {_.map(values[name], (value, key) =>
              this.buildField(
                name,
                options,
                arrayHelpers,
                errors,
                setFieldTouched,
                setFieldValue,
                touched,
                key,
                values,
              ),
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  buildValidationSchema = () => {
    return yup.object().shape({
      cardinal_communities: yup.array().of(
        yup.object().shape({
          impact: yup.string().when('target_id', {
            is: (value) => value && value !== '',
            then: yup.string().required(),
            otherwise: yup.string().notRequired(),
          }),
        }),
      ),
      non_cardinal_communities: yup.array().of(
        yup.object().shape({
          impact: yup.string().when('target_id', {
            is: (value) => value && value !== '',
            then: yup.string().required(),
            otherwise: yup.string().notRequired(),
          }),
        }),
      ),
    });
  };
}

export {Form};
