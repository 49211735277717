import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarWeek} from '@fortawesome/free-solid-svg-icons/faCalendarWeek';
import {faCheckDouble} from '@fortawesome/free-solid-svg-icons/faCheckDouble';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faCity} from '@fortawesome/free-solid-svg-icons/faCity';
import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import {faClone} from '@fortawesome/free-solid-svg-icons/faClone';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import {faPlusSquare} from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {faSortAlphaDown} from '@fortawesome/free-solid-svg-icons/faSortAlphaDown';
import {faSortAlphaUp} from '@fortawesome/free-solid-svg-icons/faSortAlphaUp';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import {faTrash} from '@fortawesome/free-solid-svg-icons/faTrash';
import _ from 'lodash';
import React from 'react';
import ReactPaginate from 'react-paginate';
import {NavLink} from 'react-router-dom';

import {buildTimestamp} from '../builders';
import {buildTitle} from '../builders';
import {withContext} from '../decorators';
import {Layout as Large} from '../layouts/large';
import {Modal as Clone} from '../modals/clone';
import {Modal as Delete} from '../modals/delete';
import {communitiesGet} from '../requests';

class Route extends React.Component {
  state = {
    //
    status: '',
    keywords: '',
    orderBy: {column: 'communities.name', direction: 'asc'},
    limit: 10,
    offset: 0,
    //
    loading: false,
    exception: null,
    count: 0,
    communities: [],
    //
    modals: {
      clone: {
        community: null,
      },
      delete: {
        community: null,
      },
    },
  };

  componentDidMount = () => {
    if (this.props.classification === 'Cardinal Community') {
      this.setState({status: 'Active'}, () => this.request());
    }
    if (this.props.classification === 'Non-Cardinal Community') {
      this.setState({status: 'Active'}, () => this.request());
    }
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    communitiesGet(
      this.props.context.token,
      this.props.classification,
      this.state.status,
      this.state.keywords,
      this.state.orderBy.column,
      this.state.orderBy.direction,
      this.state.limit,
      this.state.offset,
    ).then(
      (response) => {
        this.setState({
          loading: false,
          exception: null,
          count: response.data.count,
          communities: response.data.communities,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          count: 0,
          communities: [],
        });
      },
    );
  };

  handleKeywords = (event) => {
    this.setState({keywords: event.target.value, offset: 0}, () => this.request());
  };

  handleStatus = (status) => {
    this.setState({status: status, offset: 0}, () => this.request());
  };

  handleOrderBy = (column) => {
    let direction = 'asc';
    if (this.state.orderBy.column === column) {
      if (this.state.orderBy.direction === 'asc') {
        direction = 'desc';
      }
    }
    this.setState({orderBy: {column, direction}, offset: 0}, () => this.request());
  };

  handleCloneOpen = (community) => {
    this.setState({
      modals: {
        ...this.state.modals,
        clone: {
          community: community,
        },
      },
    });
  };

  handleCloneClose = () => {
    this.setState(
      {
        modals: {
          ...this.state.modals,
          clone: {
            community: null,
          },
        },
      },
      () => this.request(),
    );
  };

  handleDeleteOpen = (community) => {
    this.setState({
      modals: {
        ...this.state.modals,
        delete: {
          community: community,
        },
      },
    });
  };

  handleDeleteClose = () => {
    this.setState(
      {
        modals: {
          ...this.state.modals,
          delete: {
            community: null,
          },
        },
      },
      () => this.request(),
    );
  };

  handlePageChange = (data) => {
    this.setState({offset: Math.ceil(data.selected * this.state.limit)}, () => this.request());
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">
          {this.renderButton()}
          {buildTitle(this.props.classification, [])}
        </h1>
        {this.renderFilters()}
        <div className="row">
          <div className="col-12">
            {this.renderLoading()}
            {this.renderException()}
            {this.renderTable()}
            {this.renderClone()}
            {this.renderDelete()}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderButton = () => {
    if (this.props.context.type !== 'HQ') {
      return null;
    }
    if (this.props.classification !== 'Non-Cardinal Community') {
      return null;
    }
    return (
      <NavLink className="btn btn-primary float-right" to="/non-cardinal-communities/insert/set-up/community">
        <FontAwesomeIcon className="mr-2" fixedWidth icon={faPlusSquare} />
        Add Non-Cardinal Community
      </NavLink>
    );
  };

  renderFilters = () => {
    if (this.props.classification === 'Cardinal Community') {
      return (
        <div className="row">
          <div className="col-sm-12 col-md-6">{this.renderKeywords()}</div>
          <div className="col-sm-12 col-md-3">
            <a
              className={
                this.state.status === ''
                  ? 'btn btn-block btn-primary mb-3 text-white'
                  : 'btn btn-block btn-outline-primary mb-3 text-primary'
              }
              onClick={() => this.handleStatus('')}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCheckDouble} />
              Show All Communities
            </a>
          </div>
          <div className="col-sm-12 col-md-3">
            <a
              className={
                this.state.status === 'Active'
                  ? 'btn btn-block btn-primary mb-3 text-white'
                  : 'btn btn-block btn-outline-primary mb-3 text-body'
              }
              onClick={() => this.handleStatus('Active')}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCheck} />
              Show Active Communities
            </a>
          </div>
        </div>
      );
    }
    if (this.props.classification === 'Non-Cardinal Community') {
      return (
        <div className="row">
          <div className="col-sm-12">{this.renderKeywords()}</div>
        </div>
      );
    }
    return null;
  };

  renderKeywords = () => {
    return (
      <div className="align-items-center d-flex mb-3">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <FontAwesomeIcon fixedWidth icon={faSearch} />
            </div>
          </div>
          <input
            className="form-control"
            onChange={this.handleKeywords}
            type="text"
            defaultValue={this.state.keywords}
          />
        </div>
      </div>
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return (
      <div className="alert alert-danger mb-3">
        <p className="mb-0">
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faExclamationCircle} />
          {this.state.exception}
        </p>
      </div>
    );
  };

  renderTable = () => {
    if (this.state.loading) {
      return null;
    }
    if (this.state.exception) {
      return null;
    }
    if (!this.state.communities.length) {
      return (
        <div className="alert alert-danger">
          <p className="mb-0">
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faExclamationCircle} />
            Not finding the Cardinal Community you&#39;re looking for? Log a Dashboard Helpdesk ticket under the label
            of &#34;Terrain&#34;.
          </p>
        </div>
      );
    }
    const {communities, limit, offset} = this.state;
    return (
      <React.Fragment>
        <table className="table table-bordered table-hover table-sm table-responsive-sm">
          <thead className="thead-dark">
            <tr>{this.renderTHs()}</tr>
          </thead>
          <tbody>
            {_.map(communities, (community) => {
              return <tr key={community.id}>{this.renderTDs(community)}</tr>;
            })}
          </tbody>
        </table>
        <div className="align-items-center d-flex flex-column flex-lg-row">
          <p className="d-flex flex-fill justify-content-start">
            Show {offset + 1} to {Math.min(this.state.count, offset + limit)} of {this.state.count} communities
          </p>
          <div className="d-flex flex-fill justify-content-end">
            <ReactPaginate
              activeClassName={'active'}
              activeLinkClassName={'active'}
              breakClassName={'page-item'}
              breakLinkClassName={'cursor-pointer page-link'}
              breakLabel={'...'}
              containerClassName={'justify-content-center pagination'}
              marginPagesDisplayed={0}
              nextClassName={'page-item'}
              nextLabel={'>'}
              forcePage={this.state.offset / this.state.limit}
              nextLinkClassName={'cursor-pointer page-link'}
              onPageChange={this.handlePageChange}
              pageClassName={'page-item'}
              pageCount={Math.ceil(this.state.count / this.state.limit)}
              pageLinkClassName={'cursor-pointer page-link'}
              pageRangeDisplayed={5}
              previousClassName={'page-item'}
              previousLabel={'<'}
              previousLinkClassName={'cursor-pointer page-link'}
              subContainerClassName={'pagination'}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderTHs = () => {
    if (this.props.classification === 'Cardinal Community') {
      return (
        <React.Fragment>
          <th className="w-100" onClick={() => this.handleOrderBy('communities.name')}>
            Name
            {this.renderIcon('name')}
          </th>
          <th onClick={() => this.handleOrderBy('communities.property_code')}>
            Property Code
            {this.renderIcon('property_code')}
          </th>
          <th onClick={() => this.handleOrderBy('communities.group_')}>
            Group
            {this.renderIcon('group_')}
          </th>
          <th onClick={() => this.handleOrderBy('communities.cell')}>
            Cell
            {this.renderIcon('cell')}
          </th>
          <th onClick={() => this.handleOrderBy('communities.market')}>
            Market
            {this.renderIcon('market')}
          </th>
          <th onClick={() => this.handleOrderBy('unit_mix_details')}>
            Unit Mix Details Last Updated {this.renderIcon('unit_mix_details')}
          </th>
          <th className="actions">Actions</th>
        </React.Fragment>
      );
    }
    if (this.props.classification === 'Non-Cardinal Community') {
      return (
        <React.Fragment>
          <th className="w-100" onClick={() => this.handleOrderBy('name')}>
            Name
            {this.renderIcon('name')}
          </th>
          <th onClick={() => this.handleOrderBy('market')}>
            Market
            {this.renderIcon('market')}
          </th>
          <th onClick={() => this.handleOrderBy('unit_mix_details')}>
            Unit Mix Details Last Updated {this.renderIcon('unit_mix_details')}
          </th>
          <th className="actions">Actions</th>
        </React.Fragment>
      );
    }
    return null;
  };

  renderTDs = (community) => {
    if (this.props.classification === 'Cardinal Community') {
      return (
        <React.Fragment>
          <td className="w-100">{community.name}</td>
          <td>{community.property_code}</td>
          <td>{community.group}</td>
          <td>{community.cell}</td>
          <td>{community.market}</td>
          <td>{buildTimestamp(community.unit_mix_details)}</td>
          <td className="actions">
            <NavLink
              className="btn btn-primary btn-sm mr-1"
              to={`/cardinal-communities/${community.id}/set-up/competitors`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCity} />
              Competitors
            </NavLink>
            <NavLink
              className="btn btn-primary btn-sm mr-1"
              to={`/cardinal-communities/${community.id}/set-up/community`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faClipboardCheck} />
              Set-up
            </NavLink>
            <NavLink
              className="btn btn-primary btn-sm"
              to={`/cardinal-communities/${community.id}/weekly-updates/unit-mix-details`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
              Weekly Updates
            </NavLink>
          </td>
        </React.Fragment>
      );
    }
    if (this.props.classification === 'Non-Cardinal Community') {
      return (
        <React.Fragment>
          <td className="w-100">{community.name}</td>
          <td>{community.market}</td>
          <td>{buildTimestamp(community.unit_mix_details)}</td>
          <td className="actions">
            <NavLink
              className="btn btn-primary btn-sm mr-1"
              to={`/non-cardinal-communities/${community.id}/set-up/competitors`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCity} />
              Competitors
            </NavLink>
            <NavLink
              className="btn btn-primary btn-sm mr-1"
              to={`/non-cardinal-communities/${community.id}/set-up/community`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faClipboardCheck} />
              Set-up
            </NavLink>
            <NavLink
              className="btn btn-primary btn-sm mr-1"
              to={`/non-cardinal-communities/${community.id}/weekly-updates/unit-mix-details`}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faCalendarWeek} />
              Weekly Updates
            </NavLink>
            {this.renderButtons(community)}
          </td>
        </React.Fragment>
      );
    }
    return null;
  };

  renderIcon = (column) => {
    const {orderBy} = this.state;
    if (column !== orderBy.column) {
      return null;
    }
    if (orderBy.direction === 'asc') {
      return <FontAwesomeIcon className="ml-2" fixedWidth icon={faSortAlphaUp} />;
    }
    if (orderBy.direction === 'desc') {
      return <FontAwesomeIcon className="ml-2" fixedWidth icon={faSortAlphaDown} />;
    }
    return null;
  };

  renderButtons = (community) => {
    if (this.props.context.type === 'CM') {
      return null;
    }
    return (
      <React.Fragment>
        <a className="btn btn-primary btn-sm mr-1 text-white" onClick={() => this.handleCloneOpen(community)}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faClone} />
          Clone
        </a>
        <a className="btn btn-primary btn-sm text-white" onClick={() => this.handleDeleteOpen(community)}>
          <FontAwesomeIcon className="mr-2" fixedWidth icon={faTrash} />
          Delete
        </a>
      </React.Fragment>
    );
  };

  renderClone = () => {
    return (
      <Clone
        community={this.state.modals.clone.community}
        context={this.props.context}
        handleClose={this.handleCloneClose}
      />
    );
  };

  renderDelete = () => {
    return (
      <Delete
        community={this.state.modals.delete.community}
        context={this.props.context}
        handleClose={this.handleDeleteClose}
      />
    );
  };
}

Route = withContext(Route, 'Private', Large);

export {Route};
