import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding} from '@fortawesome/free-solid-svg-icons/faBuilding';
import {faCity} from '@fortawesome/free-solid-svg-icons/faCity';
import {faHotel} from '@fortawesome/free-solid-svg-icons/faHotel';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import React from 'react';
import {toast} from 'react-toastify';
import {NavLink, withRouter} from 'react-router-dom';

import Logo from '../logo.png';

class Slot extends React.Component {
  render = () => {
    return (
      <nav className="bg-primary navbar navbar-expand-lg navbar-dark">
        <NavLink className="navbar-brand py-0" to="/">
          <img alt="Logo" src={Logo} />
        </NavLink>
        <button
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          className="navbar-toggler"
          data-target="#navbar"
          data-toggle="collapse"
          type="button"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar">
          {this.renderOne()}
          {this.renderTwo()}
        </div>
      </nav>
    );
  };

  renderOne = () => {
    if (!this.props.context.isValidA()) {
      return null;
    }
    return (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink className="nav-link" exact={true} to="/cardinal-communities/select">
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faBuilding} />
            Cardinal Communities
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" exact={true} to="/non-cardinal-communities/select">
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faHotel} />
            Non-Cardinal Communities
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" exact={true} to="/competitors/select">
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faCity} />
            Weekly Updates
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" exact={true} to="/faq">
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faQuestionCircle} />
            FAQ
          </NavLink>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            onClick={() => {
              this.props.context.signOut(this.props.history.push);
              toast.success('You have signed out successfully');
            }}
          >
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faSignOutAlt} />
            Sign Out (<strong>{this.props.context.email}</strong>)
          </a>
        </li>
      </ul>
    );
  };

  renderTwo = () => {
    if (this.props.context.isValidA()) {
      return null;
    }
    const error = new URL(location.href).searchParams.get('error');
    if (error !== null) {
      return (
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <a
              className="nav-link"
              onClick={() => {
                this.props.context.signOut(this.props.history.push);
                toast.success('You have signed out successfully');
              }}
            >
              <FontAwesomeIcon className="mr-2" fixedWidth icon={faSignOutAlt} />
              Sign Out
            </a>
          </li>
        </ul>
      );
    }
    return (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink className="nav-link" exact={true} to="/api/sign-in">
            <FontAwesomeIcon className="mr-2" fixedWidth icon={faSignInAlt} />
            Sign In
          </NavLink>
        </li>
      </ul>
    );
  };
}

Slot = withRouter(Slot);

export {Slot};
