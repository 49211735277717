import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons/faSpinner';
import _ from 'lodash';
import React from 'react';

import {withContext} from '../decorators';
import {Layout as Large} from '../layouts/large';
import {faqGet} from '../requests';

class Route extends React.Component {
  state = {
    loading: false,
    exception: null,
    faq: [],
  };

  componentDidMount = () => {
    this.request();
  };

  request = () => {
    this.setState({
      loading: true,
      exception: null,
    });
    faqGet(this.props.context.token).then(
      (response) => {
        this.setState({
          loading: false,
          exception: null,
          faq: response.data.faq,
        });
      },
      (error) => {
        this.setState({
          loading: false,
          exception: error.response.data.error,
          faq: [],
        });
      },
    );
  };

  render = () => {
    return (
      <React.Fragment>
        <h1 className="mb-3 mt-3">FAQ</h1>
        {this.renderLoading()}
        {this.renderException()}
        {this.renderFAQ()}
      </React.Fragment>
    );
  };

  renderLoading = () => {
    if (!this.state.loading) {
      return null;
    }
    return (
      <div className="alert alert-primary mb-3 text-center">
        <FontAwesomeIcon fixedWidth icon={faSpinner} spin />
      </div>
    );
  };

  renderException = () => {
    if (this.state.loading) {
      return null;
    }
    if (!this.state.exception) {
      return null;
    }
    return (
      <div className="alert alert-danger">
        <p className="mb-0">{this.state.exception}</p>
      </div>
    );
  };

  renderFAQ = () => {
    return (
      <div className="mb-3">
        <div className="accordion" id="accordion">
          {_.map(this.state.faq, (value, key) => {
            return (
              <div className="card" key={key}>
                <div className="card-header">
                  <span className="cursor" data-target={`#question-${key}`} data-toggle="collapse">
                    {key + 1}. {value.question}
                  </span>
                </div>
                <div
                  className={`collapse ${key === 0 ? 'show' : ''}`}
                  data-parent="#accordion"
                  id={`question-${key}`}
                  key={key}
                >
                  <div className="card-body">{value.answer}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}

Route = withContext(Route, 'Private', Large);

export {Route};
